<script setup lang="ts">
import {computed} from "vue";
import {VTooltip} from "vuetify/lib/components/index.mjs";
import VueFeather from "vue-feather";
import {useParametersUtils} from "@/domains/parameters/composables/useParametersUtils";
import {
  getReadableImportParsingRuleValue,
  ofTooltipMatch,
  periodeToText,
} from "@oplit/shared-module";
import {
  ENTITY_DETAILS_TOOLTIP__DEFAULT_ICON_PROPS,
  TEST_IDS,
} from "@/config/constants";
import {useMainStore} from "@/stores/mainStore";
import {storeToRefs} from "pinia";
import {useI18n} from "vue-i18n";

// TODO: generic wrapper type for component props ?
type VTooltipProps = InstanceType<typeof VTooltip>["$props"];
type VueFeatherProps = InstanceType<typeof VueFeather>["$props"];

const props = withDefaults(
  defineProps<{
    entity: Record<string, unknown>;
    excludedKeys?: string[];
    displayedKeys?: string[];
    tooltipProps?: VTooltipProps;
    iconProps?: VueFeatherProps;
  }>(),
  {
    excludedKeys: () => ["extra_infos", "tags"],
    displayedKeys: () => [],
    iconProps: () =>
      ENTITY_DETAILS_TOOLTIP__DEFAULT_ICON_PROPS as VueFeatherProps,
    tooltipProps: () => ({} as VTooltipProps),
  },
);

const {t} = useI18n();
const {mappedLoadImportParsingFields} = useParametersUtils();
const {operators, machines} = storeToRefs(useMainStore());

/**
 * used for CSS binding of width
 */
const strIconSize = computed(() => {
  return typeof finalIconProps.value.size === "string"
    ? finalIconProps.value.size
    : `${finalIconProps.value.size}px`;
});

const finalDisplayedKeys = computed(() => {
  if (props.displayedKeys.length > 0) return props.displayedKeys;

  const keys = Object.keys(props.entity);

  if (props.excludedKeys.length === 0) return keys;

  return keys.filter((key) => !props.excludedKeys.includes(key));
});

const finalIconProps = computed(() => ({
  ...ENTITY_DETAILS_TOOLTIP__DEFAULT_ICON_PROPS,
  ...props.iconProps,
}));

const entityDetails = computed<{key: string; label: string; value: string}[]>(
  () => {
    return finalDisplayedKeys.value.reduce((acc, key) => {
      const match =
        mappedLoadImportParsingFields.value.find(({value}) => value === key) ||
        ofTooltipMatch.find(({field}) => field === key);

      const value =
        (props.entity[key] as {value: unknown})?.value ?? props.entity[key];

      if (["operator", "machine"].includes(key)) {
        const tagId = props.entity["tag_id"];
        const isOperator = key === "operator";

        const [workerLabel, workerRepository] = isOperator
          ? [t("Simulation.operator"), operators]
          : [t("Simulation.machine"), machines];

        const worker = workerRepository.value.find(({id}) => id === tagId);

        if (worker)
          return [...acc, {key, label: workerLabel, value: worker.name}];
      }

      if ([undefined, null, ""].includes(value as string)) return acc;

      const label = match?.label || key;

      if (key === "datelancement" && Array.isArray(value))
        return [...acc, {key, label, value: periodeToText(value)}];

      return [...acc, {key, label, value: getDisplayedValue(value)}];
    }, []);
  },
);

function getDisplayedValue(value: unknown) {
  if (typeof value === "boolean") return value ? "✔️" : "❌";

  return getReadableImportParsingRuleValue(value);
}
</script>

<template>
  <v-tooltip v-bind="tooltipProps">
    <template v-slot:activator="{props}">
      <vue-feather v-bind="{...finalIconProps, ...props}" />
    </template>

    <div class="entity-details-tooltip">
      <div
        v-for="{key, label, value} of entityDetails"
        :key="value"
        class="entity-details-tooltip__content"
      >
        <span
          :data-testid="`${TEST_IDS.ENTITY_DETAILS_TOOLTIP__LABEL_PREFIX}${key}`"
        >
          {{ label }}
        </span>

        <b>
          {{ value }}
        </b>
      </div>
    </div>
  </v-tooltip>
</template>

<style scoped lang="scss">
.entity-details-tooltip__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.vue-feather {
  flex: 0 0 v-bind(strIconSize);
}
</style>
