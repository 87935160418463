<script setup lang="ts">
import {useI18n} from "vue-i18n";
import FChip from "@/components/Global/Homemade/Commons/FChip.vue";
import FavoriteWrapper from "@/components/Commons/FavoriteWrapper.vue";
import {useMainStore} from "@/stores/mainStore";
import {UserParametersFavorites} from "@/domains/parameters/types";
import {SectorGroup} from "@/interfaces";

interface SectorGroupItemProps {
  sectorGroup: SectorGroup;
  displayPrependIcon?: boolean;
  doNotRenderFavoriteIcon?: boolean;
  /**
   * applies color for a dark background
   */
  dark?: boolean;
}

defineProps<SectorGroupItemProps>();

const {t} = useI18n();

const {variables} = useMainStore();
</script>

<template>
  <FavoriteWrapper
    :entity-id="sectorGroup.id"
    :parameters-key="UserParametersFavorites.SectorsGroups"
    :do-not-render-icon="doNotRenderFavoriteIcon"
    class="flex-1 justify-space-between"
  >
    <div class="sector-group-item flex-1">
      <OpalIcon
        v-if="displayPrependIcon"
        :stroke="dark ? variables.blanc : variables.newMainText"
        name="sector"
      />

      {{ sectorGroup.name }}

      <span :class="dark ? 'text-newSelected' : 'text-newDisableText'">
        {{
          t("Commons.sectors_count", {
            count: sectorGroup.sectors.length,
          })
        }}
      </span>

      <FChip
        v-if="sectorGroup.is_conwip"
        :color="dark ? 'white' : 'newPrimaryRegular'"
        :class="{'text-newPrimaryRegular': dark}"
        class="ml-2"
      >
        <template #prepend-icon>
          <OpalIcon
            name="ticket"
            :stroke="dark ? variables.newPrimaryRegular : variables.blanc"
          />
        </template>

        Conwip
      </FChip>
    </div>
  </FavoriteWrapper>
</template>

<style scoped lang="scss">
.sector-group-item {
  display: flex;
  align-items: center;
  gap: 6px;
  letter-spacing: normal;
}
</style>
