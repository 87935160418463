<script setup lang="ts">
import {computed} from "vue";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";
import {LOCALES} from "@oplit/shared-module";

const props = withDefaults(
  defineProps<{
    ids?: Record<(typeof LOCALES)[keyof typeof LOCALES], string>;
    pageType?: "article" | "category";
  }>(),
  {
    pageType: "article",
  },
);

const BASE_URL = "https://oplit.crisp.help";

const {userData} = storeToRefs(useMainStore());

const locale = computed(() => {
  const {locale} = userData.value;
  if (props.ids && !props.ids[locale]) return LOCALES.EN;
  return locale || LOCALES.EN;
});

const computedHRef = computed<string>(() => {
  const urlPathMembers = [BASE_URL, locale.value];

  if (props.ids) urlPathMembers.push(props.pageType, props.ids[locale.value]);

  return urlPathMembers.join("/");
});
</script>

<template>
  <a :href="computedHRef" target="_blank" rel="noopener noreferrer nofollow">
    <slot />
  </a>
</template>
