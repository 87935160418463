import _ from "lodash";

import moment from "moment";

import {
  calculateImpactOnMSD,
  computePeriod,
  removeOneEventInMany,
  getImportantKeys,
} from "@/domains/planning/domains/events/utils/eventHelper";
import iconList from "./listIcon";
import {fullName, displayedTimestamp} from "./generalHelpers";
import {parseDate, periodeToText} from "@oplit/shared-module";

//This should be duplicated server side
const fieldsCorresp = [
  {
    snake: "nb_equipe",
    camel: "nb_equipe",
    displayName: "Nombre d'équipes",
  },
  {
    snake: "nb_machine",
    camel: "nb_machine",
    displayName: "Nombre de machines",
  },
  {
    snake: "tps_cycle",
    camel: "tps_cycle",
    displayName: "Temps de cycle",
    type: "number",
  },
  {
    snake: "pourcent_prod",
    camel: "pourcent_prod",
    displayName: "Taux de production",
    type: "percent",
  },
  {
    snake: "pourcent_presence",
    camel: "pourcent_presence",
    type: "percent",
    displayName: "Taux de présence",
  },
  {
    snake: "absences",
    camel: "absences",
    displayName: "Absences",
  },
  {
    snake: "trs",
    camel: "trs",
    displayName: "TRS",
  },
  {
    snake: "arrets",
    camel: "arrets",
    displayName: "Arrêts machines",
  },
  {
    snake: "capa_equipe",
    camel: "capa_equipe",
    displayName: "Capacité par équipe",
  },
  {
    snake: "capa_max_jour",
    camel: "capa_max_jour",
    displayName: "Capacité max par jour",
  },
  {
    snake: "capa_reelle_jour",
    camel: "capa_reelle_jour",
    displayName: "Capacité réelle par jour",
  },
  {
    snake: "capa_cible",
    camel: "capa_cible",
    displayName: "Capacité cible par jour",
  },
  {
    snake: "datelancement",
    camel: "datelancement",
    displayName: "Date de lancement",
  },
  {
    snake: "quantite",
    camel: "quantite",
    displayName: "Quantité",
  },
  {
    snake: "capa_jour",
    camel: "capa_jour",
    displayName: "Capacité journalière",
  },
  {
    snake: "operateurs",
    camel: "operateurs",
    displayName: "Opérateurs",
    fieldType: "autocomplete",
  },
  {
    snake: "ouverture_machine_id",
    camel: "ouverture_machine_id",
    displayName: "Ouverture machine",
  },
];
//Rappel: duplicate server side

// Get Translation
const getT = (t: any, id: string) => {
  let translation = "";
  if (id) {
    if (t?.$root?.$t)
      if (t.$root.$t(id)) translation = t.$root.$t(id).toString();
  }
  return translation;
};

const stringToColor = (string: string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1)
    hash = string.charCodeAt(i) + ((hash << 5) - hash);

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

const parseSnapshot = function (change: any, events: any = []) {
  const doc = change.doc;
  const data = doc.data() || {};
  if ([data.status, change.type].includes("removed")) {
    events = events.filter(
      (event: any) => event.id !== doc.id && event.status !== "removed",
    );
  } else if (
    change.type === "added" &&
    !events.some((x: any) => x.id === doc.id)
  ) {
    events.push({
      id: doc.id,
      ...data,
      show: false,
    });
  } else {
    events = events.map((event: any) =>
      event.id === doc.id
        ? {
            ...event,
            ...data,
          }
        : event,
    );
  }
  return events;
};

const getImportsIds = function (imports: any): any {
  return Object.keys(imports)
    .map((key: string) => {
      const value = imports[key];
      if (!value?.id) return;
      return {
        collection: key || null,
        collections: value.data || [],
        id: value.id || null,
        date: value.date || null,
        name: value.name || null,
        ...(value.data_in_bquery && {data_in_bquery: true}),
        ...(value.from_simu && {from_simu: true}),
      };
    })
    .filter((x: any) => !!x);
};

const computePeriode = function (event: any, available = false) {
  let text = "";
  let periode: any;
  if (available && event?.periode) periode = event.periode;
  else {
    periode = _.get(event, "children.0.periode");
    if (
      event?.children?.length > 1 &&
      typeof periode === "object" &&
      periode.length
    ) {
      const all_dates = event.children.reduce((acc: any, curr: any) => {
        if (
          typeof curr.periode === "object" &&
          curr.periode.length &&
          typeof curr.periode[0] === "string" &&
          curr.periode[0].length > 4
        )
          return [...acc, ...(curr.periode || [])];
        return acc;
      }, []);
      periode = [_.min(all_dates), _.max(all_dates)];
    }
  }
  if (typeof periode === "object" && periode.toDate)
    text = moment(parseDate(periode, false, true)).calendar();
  else if (periode && typeof periode === "object" && periode.length)
    return periodeToText(periode);
  else if (periode && typeof periode === "string")
    text = moment(periode).calendar();

  return text;
};

export {
  getT,
  stringToColor,
  fieldsCorresp,
  iconList,
  computePeriod,
  removeOneEventInMany,
  getImportantKeys,
  parseSnapshot,
  calculateImpactOnMSD,
  computePeriode,
  getImportsIds,
  fullName,
  displayedTimestamp,
};
