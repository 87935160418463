import {App, defineComponent, h, render} from "vue";
import OFSidebar from "@/domains/scheduling/domains/of-sidebar/components/OFSidebar.vue";
import {createHTMLElementWithID} from "@/plugins/utils/pluginsHelper";
import type {OpenOFSidebarFunction} from "@/interfaces";

const ofSidebarZindex = 1500; // v-dialog has a z-index at 2400

/**
 * opens the scheduling OF sidebar, with operations details & logic
 * invoke with this.$openOFSidebar(operation, { OperationsRightBarPropsObject  })
 * @OperationsRightBarPropsObject : an object containing props for the OperationsRightBar component
 */
const OplitOFSidebar = {
  install(app: App) {
    const openOFSidebar: OpenOFSidebarFunction = function (
      operation,
      sidebarProps = {},
    ) {
      const ofSidebarID = "of-sidebar";

      const ofSidebarElement = document.getElementById(ofSidebarID);

      if (ofSidebarElement) ofSidebarElement.remove();

      const {container, id} = createHTMLElementWithID(ofSidebarID);

      const sidebarInstance = h(
        OFSidebar as ReturnType<typeof defineComponent>,
        {
          operation,
          sidebarProps,
          id, // required from proper removal from DOM
        },
      );

      // the selector taken has to be a child of `.v-application` for the appropriate style to be applied
      const sibling = document.getElementsByClassName("v-application__wrap")[0];

      container.style.zIndex = `${ofSidebarZindex}`;

      sibling.appendChild(container);

      sidebarInstance.appContext = app._context;
      render(sidebarInstance, container);
    };

    app.config.globalProperties.$openOFSidebar = openOFSidebar;
    app.provide("openOFSidebar", openOFSidebar);
    app.provide("ofSidebarZindex", ofSidebarZindex);
  },
};

export default OplitOFSidebar;
