<script setup lang="ts">
import {computed, inject, ref} from "vue";
import {storeToRefs} from "pinia";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import moment from "moment";
import {useSchedulingComponentsStore} from "@/stores/schedulingComponentsStore";
import {useGlobalTabs} from "@/composables/useGlobalTabs";
import {
  getReadableImportParsingRuleValue,
  DATE_FORMATS,
} from "@oplit/shared-module";
import {GLOBAL_TAB_SCHEDULING_COMPONENTS} from "@/config/constants";
import {
  Segment,
  SchedulingComponentSidebarDetailsWithRequiredAmountItem,
} from "@/interfaces";

interface SchedulingComponentsSidebarItemProps {
  component: SchedulingComponentSidebarDetailsWithRequiredAmountItem;
}

const props = defineProps<SchedulingComponentsSidebarItemProps>();

const emit = defineEmits<{
  (e: "context-changed");
}>();

const segment = inject<Segment>("segment");

const route = useRoute();
const {t} = useI18n();

const {selectedSchedulingComponent} = storeToRefs(
  useSchedulingComponentsStore(),
);

const {globalTabsPerRoute, setSelectedTab} = useGlobalTabs();

const shouldDisableTooltip = ref(false);

const hasNoStockEvolutionEntries = computed(
  () => props.component.available_stock === null,
);
const shortageAmount = computed(
  () =>
    props.component.required_amount -
    (hasNoStockEvolutionEntries.value
      ? props.component.initial_stock
      : props.component.available_stock),
);
const hasShortage = computed(() => shortageAmount.value > 0);

function setContextAndChangeSelectedTab() {
  const tabIndex = globalTabsPerRoute.value[route.name as string].indexOf(
    GLOBAL_TAB_SCHEDULING_COMPONENTS,
  );

  if (tabIndex === -1) return;

  selectedSchedulingComponent.value = props.component;

  segment.value.track("[Ordo] Component Clicked In Sidebar", {
    component: props.component.name,
  });

  setSelectedTab(tabIndex);

  // TODO: implement a way to properly programmatically close the sidebar
  shouldDisableTooltip.value = true;
  emit("context-changed");
}
</script>

<template>
  <div class="scheduling-components-sidebar-item">
    <div class="fd-flex-center gap-1 justify-space-between">
      <span
        v-tooltip="{
          text: component.name,
          location: 'top left',
          disabled: shouldDisableTooltip,
        }"
        class="text-brand-primary semi-bold underlined cursor-pointer flex-1 text-ellipsis"
        @click="setContextAndChangeSelectedTab"
      >
        {{ getReadableImportParsingRuleValue(component.name) }}
      </span>

      <span
        :class="[
          hasShortage
            ? 'text-error-primary bg-error-primary'
            : 'text-success-primary bg-success-primary',
        ]"
        class="scheduling-components-sidebar-item__status-chip"
      >
        <OpalIcon :name="hasShortage ? 'alert-triangle' : 'check'" />

        {{
          hasShortage
            ? t("SchedulingComponentsSidebarItem.chip_shortage")
            : t("SchedulingComponentsSidebarItem.chip_ok")
        }}
      </span>
    </div>

    <div class="fd-flex-center gap-1">
      <span class="text-neutral-tertiary">
        {{ t("SchedulingComponentsSidebarItem.required_amount") }}
      </span>

      <span class="text-neutral-secondary">
        {{ component.required_amount }}
      </span>

      <span v-if="hasShortage" class="text-error-primary">
        ({{
          t(
            "SchedulingComponentsSidebarItem.missing_components_amount",
            {
              amount: shortageAmount,
            },
            shortageAmount,
          )
        }})
      </span>
    </div>

    <div class="fd-flex-center gap-1">
      <span class="text-neutral-tertiary">
        {{ t("SchedulingComponentsSidebarItem.next_resupply_date") }}
      </span>

      <span class="text-neutral-secondary">
        {{
          component.next_resupply_date
            ? moment(component.next_resupply_date).format(DATE_FORMATS.FR)
            : "n/a"
        }}
      </span>

      <span v-if="component.next_resupply_date" class="text-success-primary">
        (+{{ component.next_resupply_amount }})
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.scheduling-components-sidebar-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 24px;
}

.scheduling-components-sidebar-item__status-chip {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 6px;
  border-radius: 4px;
}
</style>
