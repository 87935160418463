<template>
  <v-list-item
    v-if="(secteur || {}).id && secteur.name"
    :class="computedListClass"
    :id="computedId"
    :data-cy="niveau"
    @click.stop="onItemClick"
  >
    <div class="lines-display" v-for="idx in niveau" :key="idx" />
    <vue-feather
      v-if="hasToggle"
      :type="'chevron-' + (secteur[field] ? 'down' : 'right')"
      class="cursor-pointer mr-2"
      size="16"
      :data-cy="`chevron-secteur-${secteur.name}`"
      @click.stop="() => toggle(secteur, level, field)"
    />

    <div
      v-if="(multiSelect || singleSelect) && shouldDisplayCheckbox"
      class="d-flex align-center"
    >
      <vue-feather
        tag="div"
        :type="secteur.selected ? 'check-square' : 'square'"
        class="mr-2"
        :stroke="
          variables[secteur.selected ? 'newPrimaryRegular' : 'newSelected']
        "
        :data-testid="`sector-checkbox-${secteur.id}`"
      />
    </div>
    <v-list-item-title
      class="ml-1 fbody-2 force-body gap-8 list-item-title cursor-pointer d-flex align-center justify-space-between"
      :disabled="disableBlocked && secteur.block_simu"
    >
      <div class="d-flex align-center secteur-name-wrapper">
        <v-tooltip location="top">
          <template v-slot:activator="{props}">
            <div v-bind="props" class="secteur-name fd-flex-center">
              <vue-feather
                v-if="secteur.is_machine"
                type="tool"
                size="16"
                tag="div"
                class="mr-2"
              />
              <div
                class="secteur-name-text"
                :data-cy="`secteur-${secteur.name}`"
              >
                {{ secteur.name }}
              </div>
            </div>
            <div v-if="secteur.nb_children && !hideNbChildren" class="ml-1">
              {{ " (" + secteur.nb_children + ")" }}
            </div>
          </template>
          <div>
            {{ secteur.name }}
          </div>
        </v-tooltip>
      </div>
      <div
        v-if="(multiSelect || canSelectAll) && hasChildren"
        class="d-flex align-center py-2 text-decoration-underline"
        @click.stop="() => onSelectAll(secteur, level, isOrHasChildrenSelected)"
      >
        {{
          isOrHasChildrenSelected
            ? $t("NavListe.unselect_all")
            : $t("NavListe.select_all")
        }}
      </div>
    </v-list-item-title>
  </v-list-item>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import {storeToRefs} from "pinia";

import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "NavListe",
  props: {
    index: Number,
    niveau: Number,
    secteur: Object,
    level: Object,
    toggle: Function,
    onItemSelected: Function,
    hideNbChildren: {type: Boolean, default: false},
    multiSelect: {type: Boolean, default: false},
    singleSelect: {type: Boolean, default: false},
    onSelectAll: {type: Function, default: () => void 0},
    hasChildSelected: {type: Boolean, default: false},
    field: {type: String, default: "open"},
    disableBlocked: {type: Boolean, default: false},
    canSelectAll: {type: Boolean, default: false},
    mercateamSectors: {type: Boolean, default: false},
    onlySelectLastLevel: {type: Boolean, default: false},
  },
  setup() {
    const mainStore = useMainStore();
    const {userData, variables} = storeToRefs(mainStore);

    return {
      isDevEnv: ref<boolean>(import.meta.env.NODE_ENV === "development"),
      userData,
      variables,
    };
  },
  computed: {
    computedListClass: function () {
      const {secteur, niveau, index} = this;
      return [
        "nav-liste-item no-min-height box-parent mt-1",
        {"bg-newPrimaryRegular text-white v-list-item--active": secteur.active},
        niveau === 0 && index >= 0 ? "test-padding-sites" : "default-height",
      ];
    },

    computedId(): string {
      const {secteur} = this;
      return [secteur.type, secteur.name, secteur.level]
        .join("-")
        .replace(" ", "_");
    },
    hasToggle(): boolean {
      return this.secteur.children?.some(
        (x: any) =>
          x?.id && x?.name && !["removed", "deleted"].includes(x.status),
      );
    },
    hasChildren() {
      return (this.secteur?.children || []).filter(
        (x: any) =>
          !!x && !["removed", "deleted"].includes(x.status) && !x.is_machine,
      ).length;
    },
    hasMovexId() {
      return !!this.secteur?.movex_id;
    },
    shouldDisplayCheckbox() {
      const {
        onlySelectLastLevel,
        multiSelect,
        hasMovexId,
        hasChildren,
        secteur,
      } = this;
      return onlySelectLastLevel
        ? multiSelect && (hasMovexId || !hasChildren)
        : multiSelect || !hasChildren || secteur.selected;
    },
    isOrHasChildrenSelected(): boolean {
      return this.hasChildSelected || this.secteur.selected;
    },
  },
  mounted() {
    this.scrollIfActive();
  },
  methods: {
    computeSerieColor(serie: string) {
      return serie === "capa"
        ? this.variables.newPinkDark1
        : serie === "charge"
        ? this.variables.newPrimaryRegular
        : this.variables.newGreenRegular;
    },
    scrollIfActive() {
      if (!this.secteur?.active) return false;
    },
    onItemClick() {
      const {
        disableBlocked,
        secteur,
        level,
        isDevEnv,
        hasChildren,
        singleSelect,
        hasMovexId,
      } = this;
      if (disableBlocked && secteur.block_simu && !isDevEnv) return;
      if (singleSelect && hasChildren && !secteur.selected) return;
      const {
        level: sublevel = level.level,
        type = level.type,
        collection = level.collection,
      } = secteur;

      // we don't want to push not last-level sectors if onlySelectLastLevel is true
      if (this.onlySelectLastLevel && hasChildren && !hasMovexId) return;

      return this.onItemSelected(
        secteur,
        {level: sublevel, type, collection},
        this.field,
        !!secteur.selected,
      );
    },
  },
});
</script>

<style scoped lang="scss">
.secteur-name {
  overflow: hidden;
  width: 100%;

  &-wrapper {
    width: 100%;

    &-narrow {
      width: calc(100% - 32px);
    }
  }

  &-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.nav-liste-item {
  cursor: default;
  flex-direction: row;
  display: flex;
  min-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  & :deep(.v-list-item__content) {
    width: 100%;
    align-items: center;
    .list-item-title {
      display: flex;
      flex: 1 1 100%;
      align-items: center;
    }
  }
  .chevron-icon {
    &::after {
      background-color: transparent;
      display: none;
    }
  }
}

.test-padding-sites {
  margin-top: 12px;
  flex: 1;
}

.default-height {
  height: 24px;
}

.lines-display {
  height: 40px;
  width: 12px;
  border-right: 1px solid rgb(var(--v-theme-newSelected));
  margin-right: 20px;
  flex-shrink: 0;
}
</style>
<style lang="scss">
.width-badges {
  width: 54px;
}
.imports-badges {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 100%;
}
</style>
