<template>
  <v-timeline-item
    :class="['mb-2', computedTimelineItemClasses]"
    :id="isSelected ? 'scrollable-general' : ''"
    :dot-color="variables.newSelected"
    fill-dot
    size="9px"
  >
    <div
      class="fd-flex-center cursor-pointer"
      @click="() => (isSelected ? selectOp() : selectOp(op))"
    >
      <span class="fbody-1">
        <div :class="['fd-flex-center', lightOpacity]">
          <div
            :class="[
              isSelected ? 'font-weight-bold text-newSubText' : 'text-noir',
            ]"
            class="fd-flex-center gap-4"
          >
            {{ computedOpName }}
            <a v-if="op.link_url" :href="op.link_url" target="_blank">
              <vue-feather tag="div" type="external-link" size="12" />
            </a>

            <EntityDetailsTooltip
              v-if="clientParameters.display_operation_details"
              :entity="fullOP"
              :displayed-keys="[
                'op_id',
                'op_sequence',
                ...(clientParameters.has_conwip ? ['operator', 'machine'] : []),
                ...Object.keys(op.extra_infos || {}),
              ]"
            />

            <DevHelper>({{ op.op_id }})</DevHelper>
          </div>
        </div>
        <div class="fcaption text-newDisableText">
          <v-tooltip
            location="bottom"
            content-class="px-2 py-1"
            :z-index="zIndex"
          >
            <template v-slot:activator="{props}">
              <span v-bind="props" :class="lightOpacity">
                <span v-if="opDoneDate">
                  {{ $t("operation.done_at") }}
                  {{ prettyOpDoneDate }}
                </span>
                <span
                  v-else
                  :data-end-date="computedFluxDate"
                  :data-erp-date="computedErpDate"
                >
                  {{ $t("scheduling.end_estimated_on") }}
                  {{ dateFormat(computedFluxDate, "DD/MM/YYYY") }}
                </span>
              </span>
            </template>
            <div>
              <div v-if="computedErpDate">
                {{ $t("operation.status.erp-date") }}
                <strong>
                  {{ dateFormat(computedErpDate, "DD/MM/YYYY") }}</strong
                >
              </div>
              <div v-if="computedDate">
                {{ $t("operation.planned_at") }}
                <strong>
                  {{ dateFormat(computedDate, "DD/MM/YYYY") }}
                </strong>
              </div>
              <div>
                {{
                  opDoneDate
                    ? $t("operation.done_at")
                    : $t("scheduling.end_estimated_on")
                }}
                <strong>
                  {{ dateFormat(computedFluxDate, "DD/MM/YYYY") }}
                </strong>
              </div>
              <div v-if="computedOperationRealDelta">
                {{ $t("operation.status.erp_date_diff") }} :
                <strong>
                  {{ computedOperationRealDelta }}{{ $t("global.day_letter") }}
                </strong>
              </div>
              <div v-if="shouldDisplayStatusAvailableAtInTooltip">
                {{ $t("operation.available_on") }}
                <strong>
                  {{ dateFormat(op.status_available_at, "DD/MM/YYYY") }}
                </strong>
              </div>
            </div>
          </v-tooltip>
          <span
            v-if="computedOperationDelay"
            :class="[
              'ml-1',
              computedOperationIsLate
                ? 'text-newPinkRegular'
                : 'text-newGreenRegular',
            ]"
          >
            {{ computedOperationDelay }}
          </span>
        </div>
      </span>
      <div
        :class="['fd-flex-center justify-end op-chip ml-auto', lightOpacity]"
      >
        <div v-if="!isSelected">
          <v-tooltip
            v-if="op.new_fault || op.fault"
            location="top"
            :z-index="zIndex"
          >
            <template v-slot:activator="{props}">
              <v-img
                v-bind="props"
                width="13"
                :src="getAssetURL('incident-signe.svg')"
                cover
              />
            </template>
            <div>{{ $t("operation.fault") }}</div>
          </v-tooltip>
        </div>
        <OperationStatus v-if="!isSelected" :operation="op" disabled />
        <vue-feather
          class="mx-2"
          :type="isSelected ? 'chevron-up' : 'chevron-down'"
          size="18px"
        />
      </div>
    </div>

    <FluxDataBody
      v-if="isSelected"
      class="mt-4"
      :op="fullOP"
      :propagate-ops-changes="propagateOpsChanges"
      :z-index="zIndex"
      :can_make_change="!readOnly"
    />
  </v-timeline-item>
</template>
<script lang="ts">
import {PropType, defineComponent, toRefs, computed} from "vue";
import {storeToRefs} from "pinia";
import moment from "moment";
import {getReadableImportParsingRuleValue} from "@oplit/shared-module";
import FluxDataBody from "@/domains/scheduling/domains/of-sidebar/components/FluxDataBody.vue";
import EntityDetailsTooltip from "@/components/Commons/EntityDetailsTooltip.vue";
import {CSS_FLUX_SELECTED_OP_CLASS, OF_STATUS} from "@/config/constants";
import {getEstimatedEndDate, getOperationStatus} from "@oplit/shared-module";
import {dateFormat} from "@/tscript/utils/dateUtils";
import {getAssetURL} from "@/tscript/utils/generalHelpers";
import {useMainStore} from "@/stores/mainStore";
import {SchedulingOperation} from "@/interfaces";
import {useOperationStatus} from "@/composables/scheduling/useOperationStatus";
import OperationStatus from "@/components/Scheduling/Operations/OperationStatus.vue";

export default defineComponent({
  name: "flux-element",
  components: {EntityDetailsTooltip, FluxDataBody, OperationStatus},
  props: {
    isSelected: {type: Boolean, default: false},
    op: {
      type: Object as PropType<SchedulingOperation>,
      default: () => ({} as SchedulingOperation),
    },
    initialOp: {type: Object, default: () => ({})},
    selectedOpId: {type: String},
    selectOp: {type: Function, default: () => void 0},
    propagateOpsChanges: {type: Function, default: () => void 0},
    operationsList: {type: Array, default: () => []},
    zIndex: {type: Number, default: 2000},
    readOnly: {type: Boolean, default: false},
    index: {type: Number, default: -1},
  },
  setup(props) {
    const {clientParameters, variables} = storeToRefs(useMainStore());
    const {op} = toRefs(props);
    const {displayedOperationStatus} = useOperationStatus(op);

    const opDoneDate = computed(() => {
      const {
        op: {status_done_at, op_status, new_status, day_date, new_date},
      } = props;
      if (getOperationStatus({op_status, new_status}) !== OF_STATUS.DONE)
        return;
      return status_done_at || new_date || day_date;
    });
    const shouldDisplayStatusAvailableAtInTooltip = computed(() => {
      if (props.index !== 0) return false;
      if (!props.op?.status_available_at) return false;
      return true;
    });

    return {
      clientParameters,
      variables,
      dateFormat,
      getAssetURL,
      displayedOperationStatus,
      opDoneDate,
      shouldDisplayStatusAvailableAtInTooltip,
    };
  },
  computed: {
    computedErpDate() {
      return this.op?.erp_date ?? this.op?.initial_date;
    },
    computedDate() {
      return this.op?.new_date ?? this.op?.day_date;
    },
    computedFluxDate() {
      const {op, computedDate, opDoneDate} = this;
      const {
        of_delta,
        erp_date,
        day_date,
        first_active_op_theoric_date,
        first_active_op_planned_date,
        is_day_date_modified,
        all_ops_done,
        nb_ops_done,
      } = op || {};
      if (opDoneDate) return opDoneDate;
      const estimatedEndDate = getEstimatedEndDate(
        first_active_op_theoric_date,
        +of_delta,
        {
          initial_date: erp_date,
          planned_date: day_date,
          first_active_op_theoric_date,
          first_active_op_planned_date,
          is_day_date_modified,
          all_ops_done,
          has_of_started: !!nb_ops_done,
        },
      );
      return estimatedEndDate ?? opDoneDate ?? computedDate;
    },
    fullOP(): SchedulingOperation {
      return {
        ...(this.op.extra_infos || {}),
        ...(this.op.internal_extra_infos || {}),
        ...(this.op || {}),
      };
    },
    computedTimelineItemClasses(): Array<string> {
      const {
        isSelected,
        selectedOpId,
        initialOp: {op_id: initialOpId},
        op: {op_id: currentOpId},
        displayedOperationStatus,
      } = this;

      const classes: Array<string> = [];

      if (
        initialOpId === currentOpId &&
        initialOpId === selectedOpId &&
        !isSelected
      ) {
        classes.push("initial-op");
        return classes;
      }
      if (isSelected) classes.push(CSS_FLUX_SELECTED_OP_CLASS, "pt-3", "pb-4");
      if (displayedOperationStatus.text === "done" && !isSelected)
        classes.push("done-task");

      return classes;
    },
    lightOpacity() {
      const {
        isSelected,
        displayedOperationStatus: {text: operationStatus},
      } = this;
      return !isSelected && operationStatus === "done" ? "light-opacity" : "";
    },
    computedOperationRealDelta() {
      const {
        op: {erp_date},
        opDoneDate,
      } = this;
      if (!opDoneDate || !erp_date) return;
      return moment(opDoneDate).diff(erp_date, "days");
    },
    computedOperationIsLate() {
      const {computedOperationRealDelta} = this;
      if (!computedOperationRealDelta) return;
      return computedOperationRealDelta > 0;
    },
    computedOperationDelay() {
      const {computedOperationRealDelta, computedOperationIsLate} = this;
      if (!computedOperationRealDelta) return;

      return `(${
        computedOperationIsLate ? "+" : ""
      }${computedOperationRealDelta}${this.$t("global.day_letter")})`;
    },
    prettyOpDoneDate() {
      return moment(this.opDoneDate).format("DD/MM/YYYY");
    },
    computedOpName() {
      const {
        op: {op_name},
      } = this;
      return getReadableImportParsingRuleValue(op_name);
    },
  },
  methods: {
    forwardEvent(eventName = "", event: any = {}) {
      this.$emit(eventName, event);
    },
  },
});
</script>

<style scoped lang="scss">
.selected-op {
  background-color: rgb(var(--v-theme-newSubBackground));
}
.light-opacity {
  opacity: 0.23;
}
</style>
