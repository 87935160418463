import {inject} from "vue";
import {useI18n} from "vue-i18n";

import {OpenDialogFunction, Segment} from "@/interfaces";
import {OF_STATUS} from "@/config/constants";

export function useSchedulingOperationStatusChange() {
  const openDialog = inject<OpenDialogFunction>("openDialog");
  const segment = inject<Segment>("segment");

  const {t} = useI18n();

  async function handleOperationStatusChangeConfirmation({
    update,
    operation,
  }: {
    update: {new_status?: string};
    operation: {op_status?: string; op_name?: string; of_id?: string};
  }): Promise<boolean> {
    const {op_status, op_name, of_id} = operation;
    const {new_status} = update;

    let hasConfirmed = true;
    let modalAction = "none";

    if (new_status === OF_STATUS.DONE && op_status !== OF_STATUS.ON_GOING) {
      hasConfirmed = await openDialog({
        header: t("scheduling.status_update__header"),
        message: t("scheduling.status_update__body", {op_name, of_id}),
        validateText: t("scheduling.status_update__confirm"),
        type: "warning",
        hidePrefix: true,
        hideIcon: true,
        disableHeaderColor: true,
        confirmBtnProps: {
          filled: false,
          outlined: true,
        },
        action: () => {
          modalAction = "confirm";
        },
        onCancelClicked: () => {
          modalAction = "cancel";
        },
      });
    }

    segment.value.track("[Ordo] Operation Status Updated", {
      modal_action: modalAction,
      old_status: op_status,
      new_status,
      of_id,
    });

    return hasConfirmed;
  }

  return {
    handleOperationStatusChangeConfirmation,
  };
}
