<script setup lang="ts">
import {computed, defineProps, h, ref, useAttrs, watch, type VNode} from "vue";

interface OpalIconProps {
  name: string;
  size?: string | number;
  backgroundColor?: string;
  clickable?: boolean;
}

const props = withDefaults(defineProps<OpalIconProps>(), {
  size: "16",
});
const svgRaw = ref<string | null>(null);

const attrs = useAttrs();

const wrapperClasses = computed<string[]>(() => {
  const classes = [`opal-icon opal-icon__${props.name}`];

  if (props.clickable) classes.push("cursor-pointer");
  if (props.backgroundColor) classes.push(`bg-${props.backgroundColor}`);

  return classes;
});

watch(
  () => props.name,
  async () => {
    try {
      const response = await fetch(
        new URL(`../../assets/icons/${props.name}.svg`, import.meta.url).href,
      );

      if (!response.ok) throw new Error("Icon not found");

      const text = await response.text();
      if (!text.includes("<svg")) throw new Error("Invalid SVG content");

      svgRaw.value = text;
    } catch (error) {
      svgRaw.value = null;
    }
  },
  {immediate: true},
);

function render(): VNode | null {
  if (!svgRaw.value) return null;

  const parser = new DOMParser();
  const doc = parser.parseFromString(svgRaw.value, "image/svg+xml");
  const svgElement = doc.documentElement;

  for (const attr of ["fill", "stroke"])
    if (attrs[attr]) svgElement.setAttribute(attr, attrs[attr] as string);

  if (props.size) {
    svgElement.setAttribute("width", `${props.size}`);
    svgElement.setAttribute("height", `${props.size}`);
  }

  return h("i", {
    class: wrapperClasses.value,
    style: {flex: `0 0 ${props.size}px`},
    "data-icon": props.name,
    innerHTML: svgElement.outerHTML,
  });
}
</script>

<template>
  <component v-if="svgRaw" :is="render" />
</template>

<style scoped lang="scss">
.opal-icon {
  display: flex;

  &[class*="bg-"] {
    padding: 8px;
    border-radius: 8px;
  }
}
</style>
