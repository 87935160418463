<script setup lang="ts">
import _ from "lodash";
import {computed, inject, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {storeToRefs} from "pinia";
import moment from "moment";
import FTextField from "@/components/Global/Homemade/Inputs/FTextField.vue";
import FButton from "@/components/Global/Homemade/Buttons/FButton.vue";
import Modal from "@/components/Global/Modals/Modal.vue";
import OperationTags from "@/components/Scheduling/Operations/OperationTags.vue";
import SubOFTable from "@/domains/scheduling/domains/sub-of/components/SubOFTable.vue";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useMainStore} from "@/stores/mainStore";
import {n} from "@/tscript/utils/generalHelpers";
import {getOperationDuration} from "@/tscript/utils/schedulingUtils";
import {OF_STATUS} from "@/config/constants";
import {
  getOFUnit,
  pgOpsMapFn,
  getObjectDiff,
  DATE_DEFAULT_FORMAT,
  PARAMETERS_IMPORT_PARSING_RULES_CONCATENATION_EXPRESSION,
  type SchedulingTag,
  type LocalSchedulingOp,
  type LoadInternalExtraInfos,
} from "@oplit/shared-module";
import {
  OpenDialogFunction,
  OpenSnackbarFunction,
  SchedulingOperation,
  Segment,
} from "@/interfaces";

interface SubOFModalProps {
  of: SchedulingOperation;
}

const props = defineProps<SubOFModalProps>();
const emit = defineEmits<{
  (e: "close-modal");
  (
    e: "sub-of-created",
    subOF: {
      of_id: SchedulingOperation["of_id"];
      quantite_of: SchedulingOperation["quantite_of"];
      unite_of: SchedulingOperation["unite_of"];
      operations: Record<string, unknown>[];
      tags: SchedulingOperation["tags"];
    },
  );
  (
    e: "sub-of-updated",
    subOF: {
      of_id: SchedulingOperation["of_id"];
      quantite_of: SchedulingOperation["quantite_of"];
    },
  );
  (e: "sub-of-deleted", subOfId: SchedulingOperation["of_id"]);
  (e: "quantite-of-updated", quantiteOF: number);
}>();

const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");
const openDialog = inject<OpenDialogFunction>("openDialog");
const segment = inject<Segment>("segment");

const {t} = useI18n();
const {selectedSimulation, shouldHandleOPDuration} = storeToRefs(
  useSchedulingStore(),
);
const {sendSchedulingLoadEventToBackend} = useSchedulingStore();
const {apiClient, userData} = storeToRefs(useMainStore());

const localOperations = ref<SchedulingOperation[]>([]);
const originalOperations = ref<SchedulingOperation[]>([]);
// all operations are selected by default
const selectedOperations = ref<string[]>([]);
const newOFQuantity = ref<number>(null);
const newSubOFQuantity = ref<number>(null);
const saving = ref(false);
const addedTags = ref<string[]>([...(props.of.tags || [])]);

const subOFIdentifier = computed(() =>
  props.of.is_update
    ? props.of.of_id
    : `${ofID.value}-${props.of.sub_ofs_count + 1}`,
);
const modalTitle = computed(() =>
  t("SubOFModal.title", {ofName: subOFIdentifier.value}),
);
const sortedOperations = computed<SchedulingOperation[]>(() =>
  _.sortBy(localOperations.value, "op_sequence").map((operation) => ({
    ...operation,
    operationsLength: localOperations.value.length,
  })),
);
const ofQuantiteOF = computed<number>(() =>
  n(props.of.is_update ? props.of.parent_quantite_of : props.of.quantite_of),
);
const quantitiesSumDelta = computed(() => {
  if (!newOFQuantity.value && !newSubOFQuantity.value) return 0;
  return n(newOFQuantity.value + newSubOFQuantity.value) - ofQuantiteOF.value;
});
const ofID = computed<string>(() =>
  props.of.is_update ? props.of.parent_of_id : props.of.of_id,
);
const canConfirmModal = computed<boolean>(() => {
  if (selectedOperations.value.length === 0) return false;
  return newOFQuantity.value != null || newSubOFQuantity.value != null;
});
const calculatedNewOFQuantity = computed<number>(() => {
  if (newOFQuantity.value != null) return newOFQuantity.value;

  const calculatedValue = +(
    ofQuantiteOF.value - (newSubOFQuantity.value || 0)
  ).toFixed(2);
  // if the quantity for the subOF exceeds the original quantity and no new quantity is input, the new quantity is set to 0
  return Math.max(calculatedValue, 0);
});
const calculatedNewSubOFQuantity = computed<number>(() => {
  if (newSubOFQuantity.value != null) return newSubOFQuantity.value;

  const [min, max] = [ofQuantiteOF.value, newOFQuantity.value || 0].sort(
    (a, b) => a - b,
  );
  // if the new quantity for the original OF exceeds its original quantity and no subOF quantity is input, the new subOF quantity is set to the delta
  return +(max - min).toFixed(2);
});

type LocalSchedulingOperationCommonKeys = Pick<
  SchedulingOperation,
  | "client_id"
  | "simulation_id"
  | "tags"
  | "type"
  | "unite_of"
  | "unite_op"
  | "collection"
>;
function getOperationCommonKeys(): LocalSchedulingOperationCommonKeys {
  const [firstOperation] = localOperations.value;
  const commonKeys = [
    "client_id",
    "collection",
    "customer",
    "famille",
    "fast_track",
    "ref_article",
    "ref_client",
    "simulation_id",
    "tags",
    "type",
    "unite_of",
    "unite_op",
  ];

  const operationCommonKeys = {} as LocalSchedulingOperationCommonKeys;

  for (const key of commonKeys) operationCommonKeys[key] = firstOperation[key];

  return operationCommonKeys;
}
function getInternalExtraInfos(operation: SchedulingOperation) {
  const internalExtraInfos: LoadInternalExtraInfos = {
    parent_of_id: ofID.value,
  };

  const keys = ["machine_tag_id", "op_duration", "pdc", "unite_op", "unite_of"];

  for (const key of keys)
    if (operation[key]) internalExtraInfos[key] = operation[key];

  return internalExtraInfos;
}
/**
 * returns extra_infos common to all operations, to pass as value for custom operations' `extra_infos`
 */
function getCommonExtraInfos(): SchedulingOperation["extra_infos"] {
  if (localOperations.value.length === 0) return {};

  const firstOperation = localOperations.value[0];

  return _.pickBy(firstOperation.extra_infos, (value: unknown, key: string) =>
    localOperations.value.every((obj) => obj.extra_infos?.[key] === value),
  );
}
function getOperationOpId(index: number) {
  return [subOFIdentifier.value, index].join(
    PARAMETERS_IMPORT_PARSING_RULES_CONCATENATION_EXPRESSION,
  );
}
function addOperation() {
  const lastOperation = _.maxBy(localOperations.value, "op_sequence");

  const newOperationDayDate = moment(lastOperation.day_date)
    .add(1, "day")
    .format(DATE_DEFAULT_FORMAT);
  const newOperationDayDateWithEventualShift = `${newOperationDayDate}${lastOperation.day_date.slice(
    newOperationDayDate.length,
  )}`;

  const op_sequence = localOperations.value.length + 1;

  const newOperation: SchedulingOperation = {
    ...getOperationCommonKeys(),
    quantite_of: calculatedNewSubOFQuantity.value,
    op_id: getOperationOpId(op_sequence),
    is_custom: true,
    day_date: newOperationDayDateWithEventualShift,
    op_sequence,
  };

  localOperations.value.push(newOperation);
  selectedOperations.value.push(newOperation.op_id);
}
function mapArrayWithOpSequenceAsIndex<T>(operations: T[]): T[] {
  return operations.map((operation, index) => ({
    ...operation,
    // used to change operations order
    op_sequence: index + 1,
  }));
}
function removeRow(row: SchedulingOperation) {
  localOperations.value = mapArrayWithOpSequenceAsIndex(
    localOperations.value.filter(({op_id}) => row.op_id !== op_id),
  );
}
function updateRows(payload: {
  op_id: SchedulingOperation["op_id"];
  field: string;
  value: string;
}) {
  const {op_id, field, value} = payload;

  localOperations.value = localOperations.value.map((op) =>
    op_id !== op.op_id
      ? op
      : {
          ...op,
          [field]: value,
        },
  );
}
function updateOperationOrder(payload: {
  operation: SchedulingOperation;
  new_order: number;
}) {
  const {operation, new_order} = payload;

  const newOperations = localOperations.value.filter(
    ({op_id}) => op_id !== operation.op_id,
  );
  const newIndex = new_order - 1;

  const previousOperations = newOperations.slice(0, newIndex);
  const nextOperations = newOperations.slice(newIndex);

  localOperations.value = mapArrayWithOpSequenceAsIndex([
    ...previousOperations,
    operation,
    ...nextOperations,
  ]);
}
function addTags(tags: SchedulingTag[]) {
  addedTags.value.push(...tags.map(({id}) => id));
}
function getTextFieldNumericalUpdate(inputValue: string) {
  const cleanedValue = inputValue
    .split("")
    .filter((char) => char.match(/[0-9.]/g))
    .join("");
  if (cleanedValue === "") return;
  return Number.isNaN(+cleanedValue) ? 0 : +cleanedValue;
}
function getEventExtraInfos(operation: {parent_of_id?: string}) {
  const parent_of_id = operation.parent_of_id || props.of.parent_of_id;

  if (!parent_of_id) return {};

  return {
    parent_of_id,
  };
}
function getFormattedNewOperation(
  operation: SchedulingOperation,
  keysToReturn?: string[],
) {
  const op_duration = getOperationDuration(operation);
  const internal_extra_infos = getInternalExtraInfos(operation);
  const quantite = +(operation.quantite || 0);
  const extra_infos =
    Object.keys(operation.extra_infos || {}).length > 0
      ? operation.extra_infos
      : getCommonExtraInfos();

  const formattedOperation = {
    ...operation,
    tags: addedTags.value,
    of_id: subOFIdentifier.value,
    op_status: OF_STATUS.PENDING,
    /**
     * `erp_status` is a condition for the ability to change the status\
     * this being a manually-created OF it won't be impacted by any
     * upcoming import: we let the user handle status changes without restriction
     */
    erp_status: null,
    op_id: getOperationOpId(+operation.op_sequence),
    load: quantite,
    erp_date: operation.erp_date ?? operation.day_date,
    simulation_id: selectedSimulation.value.id,
    quantite_of: calculatedNewSubOFQuantity.value,
    quantite,
    quantity_produced: 0,
    // required for updating value of the sub-of of a sub-of
    parent_of_id: internal_extra_infos.parent_of_id,
    op_duration,
    extra_infos,
    internal_extra_infos,
  };

  if (!keysToReturn?.length) return formattedOperation;

  return keysToReturn.reduce(
    (acc, key) => ({...acc, [key]: formattedOperation[key]}),
    {} as typeof formattedOperation,
  );
}
async function updateParentOFQuantity() {
  const consideredQuantiteOF = props.of.is_update
    ? props.of.parent_quantite_of
    : ofQuantiteOF.value;

  if (consideredQuantiteOF === calculatedNewOFQuantity.value) return;

  const initial = {...props.of.operations[0]};
  if (props.of.is_update) initial.of_id = props.of.parent_of_id;

  try {
    // updating current OF `quantite_of`
    await sendSchedulingLoadEventToBackend({
      data: [
        {
          initial,
          update: {
            quantite_of: calculatedNewOFQuantity.value,
            event_level: "OF",
            event_type: "UPDATE",
          },
        },
      ],
      should_update_all_of: true,
      should_handle_op_duration: shouldHandleOPDuration.value,
      should_force_local_changes: true,
    });

    emit("quantite-of-updated", calculatedNewOFQuantity.value);
  } catch (error) {
    openSnackbar(null, "GENERIC_ERROR");
  }
}
async function preventMalformedOperations(
  operations: {op_name?: string; secteur_id?: string; load?: number}[],
): Promise<boolean> {
  if (
    operations.some(
      ({op_name, secteur_id, load}) =>
        !op_name || !secteur_id || Number.isNaN(load),
    )
  ) {
    return await new Promise((resolve) => {
      openDialog({
        type: "negative",
        message: t("SubOFModal.prevent_malformed_operations_message"),
        hidePrefix: true,
        action: () => resolve(true),
        onCancelClicked: () => resolve(false),
        validateText: props.of.is_update
          ? t("SubOFModal.prevent_malformed_operations_validate_text_update")
          : t("SubOFModal.prevent_malformed_operations_validate_text_create"),
      });
    });
  }

  return new Promise((resolve) => resolve(true));
}
async function createNewSubOF() {
  const operationsToCreate = localOperations.value
    .filter(({op_id}) => selectedOperations.value.includes(op_id))
    .map((operation) => getFormattedNewOperation(operation));

  const hasConfirmed = await preventMalformedOperations(operationsToCreate);

  if (!hasConfirmed) return;

  saving.value = true;

  try {
    await updateParentOFQuantity();

    /**
     * here we create the operations for the sub-OF from the original OF
     * NB: these are grouped by sequence in OperationsRightBar,
     * therefore we only duplicate the visible list and not the full list
     */
    await sendSchedulingLoadEventToBackend({
      data: [
        ...operationsToCreate.map((operation) => ({
          initial: operation as LocalSchedulingOp,
          update: {
            ...operation,
            event_extra_infos: getEventExtraInfos(operation),
            event_type: "CREATE",
          },
        })),
      ],
      should_handle_op_duration: shouldHandleOPDuration.value,
      should_force_local_changes: true,
    });

    const subOF = {
      of_id: subOFIdentifier.value,
      quantite_of: calculatedNewSubOFQuantity.value,
      unite_of: props.of.unite_of,
      operations: operationsToCreate,
      tags: addedTags.value,
    };

    emit("sub-of-created", subOF);

    openSnackbar({
      message: t("SubOFModal.creation_success_message"),
      type: "positive",
    });
    const {operations, ...rest} = subOF;
    segment.value.track("[Ordo] Sub-OF Created", {
      ...rest,
      operations_count: operations.length,
    });

    emit("close-modal");
  } catch (error) {
    openSnackbar({
      message: t("SubOFModal.creation_failure_message"),
      type: "negative",
    });
  } finally {
    saving.value = false;
  }
}
async function updateSubOF() {
  const deletedOperations = localOperations.value.filter(
    (operation) => !selectedOperations.value.includes(operation.op_id),
  );
  const newOperations = localOperations.value.filter(
    (operation) =>
      !originalOperations.value.find(({op_id}) => operation.op_id === op_id),
  );
  const updatedOperations = localOperations.value.filter((operation) => {
    // if we updated `newSubOFQuantity`, every operation should have its `quantite_of` updated
    if (calculatedNewSubOFQuantity.value !== operation.quantite_of) return true;
    const originalOperation = originalOperations.value.find(
      ({op_id}) => op_id === operation.op_id,
    );
    if (!originalOperation) return false;
    return !_.isEqual(originalOperation, operation);
  });

  for (const operationsArray of [
    deletedOperations,
    newOperations,
    updatedOperations,
  ]) {
    const hasConfirmed = await preventMalformedOperations(operationsArray);
    if (!hasConfirmed) return;
  }

  saving.value = true;

  try {
    await updateParentOFQuantity();

    if (deletedOperations.length > 0) {
      await sendSchedulingLoadEventToBackend({
        data: [
          ...deletedOperations.map((operation) => ({
            initial: operation as LocalSchedulingOp,
            update: {
              ...operation,
              event_type: "DELETE",
            },
          })),
        ],
        should_force_local_changes: true,
        should_handle_op_duration: shouldHandleOPDuration.value,
      });
    }

    if (newOperations.length > 0) {
      await sendSchedulingLoadEventToBackend({
        data: [
          ...newOperations.map((operation) => {
            const formattedOperation = getFormattedNewOperation(operation);
            return {
              initial: formattedOperation as LocalSchedulingOp,
              update: {
                ...formattedOperation,
                event_extra_infos: getEventExtraInfos(operation),
                event_type: "CREATE",
              },
            };
          }),
        ],
        should_handle_op_duration: shouldHandleOPDuration.value,
        should_force_local_changes: true,
      });
    }

    if (updatedOperations.length > 0) {
      await sendSchedulingLoadEventToBackend({
        data: [
          ...updatedOperations.map((operation) => {
            const originalOperation = originalOperations.value.find(
              ({op_id}) => op_id === operation.op_id,
            );

            Object.assign(operation, {
              quantite_of: calculatedNewSubOFQuantity.value,
            });

            const diff = getObjectDiff(operation, originalOperation);

            // FIXME: type
            const formattedOperation: Record<string, unknown> =
              getFormattedNewOperation(operation, Object.keys(diff));

            // FIXME: can't we update `load` directly instead of `quantite`?
            if ("quantite" in formattedOperation)
              formattedOperation.load = formattedOperation.quantite;

            return {
              initial: operation as LocalSchedulingOp,
              update: {
                ...formattedOperation,
                event_type: "UPDATE",
              },
            };
          }),
        ],
        should_force_local_changes: true,
        should_handle_op_duration: shouldHandleOPDuration.value,
      });
    }

    openSnackbar({
      message: t("SubOFModal.modification_success_message"),
      type: "positive",
    });

    segment.value.track("[Ordo] Sub-OF Updated");

    emit("sub-of-updated", {
      of_id: subOFIdentifier.value,
      quantite_of: calculatedNewSubOFQuantity.value,
    });

    emit("close-modal");
  } catch (error) {
    openSnackbar({
      message: t("SubOFModal.modification_failure_message"),
      type: "negative",
    });
  } finally {
    saving.value = false;
  }
}
async function confirmModal() {
  async function onConfirmModal() {
    if (props.of.is_update) await updateSubOF();
    else await createNewSubOF();
  }

  const hasCoherentDates = localOperations.value.every(
    ({day_date}, index, operations) => {
      const nextOperation = operations[index + 1];
      if (!nextOperation) return true;
      return day_date <= nextOperation.day_date;
    },
  );

  if (!hasCoherentDates) {
    openDialog({
      type: "negative",
      message: t("SubOFModal.confirm_incohorent_dates_message", {
        ofID: subOFIdentifier.value,
      }),
      action: async () => {
        await onConfirmModal();
      },
      hidePrefix: true,
    });
  } else await onConfirmModal();
}
// FIXME: harmonize with OperationsRightBar ?
async function fetchSubOFOperations() {
  if (!props.of.is_update) return;

  const {client_id} = userData.value || {};
  const {id: simulation_id} = selectedSimulation.value || {};
  if (!client_id || !simulation_id || !props.of.of_id) return;

  const results = await apiClient.value.ofsOpsFn(
    simulation_id,
    props.of.of_id,
    {
      prepare_sidebar_ops: true,
    },
  );

  return pgOpsMapFn(results, {
    of_ordering: true,
    keepOpsDone: true,
    removeSmoothedDuplicates: true,
    groupBySequence: true,
    keepExtraInfos: true,
  });
}
async function deleteSubOF() {
  const {of_id, operations} = props.of;
  const [{op_id, op_sequence}] = operations;
  // this contains the keys that are mandatory for proper deletion of a sub-of
  const constructedOperationObject = {
    of_id,
    op_id,
    op_sequence,
  } as LocalSchedulingOp;

  openDialog({
    type: "negative",
    message: t("SubOFModal.delete_sub_of_message", {ofID: of_id}),
    action: async () => {
      try {
        await sendSchedulingLoadEventToBackend({
          data: [
            // updating parent OF `quantite_of`
            {
              initial: {
                of_id: props.of.parent_of_id,
              } as LocalSchedulingOp,
              update: {
                quantite_of:
                  calculatedNewSubOFQuantity.value +
                  calculatedNewOFQuantity.value,
                event_level: "OF",
              },
            },
            // deleting the sub-OF
            {
              initial: constructedOperationObject,
              update: {
                ...constructedOperationObject,
                event_level: "OF",
                event_type: "DELETE",
              },
            },
          ],
          should_update_all_of: true,
          should_force_local_changes: true,
          should_handle_op_duration: shouldHandleOPDuration.value,
        });
        emit("sub-of-deleted", of_id);

        emit("close-modal");
      } catch (error) {
        openSnackbar(null, "GENERIC_ERROR");
      }
    },
    hidePrefix: true,
    validateText: t("SubOFModal.delete_button_text"),
    confirmBtnProps: {
      white: true,
      filled: true,
    },
  });
}

async function initializeLocalData() {
  let operations = [];
  if (props.of.is_update) {
    newOFQuantity.value = props.of.parent_quantite_of;
    newSubOFQuantity.value = props.of.quantite_of;

    operations = (await fetchSubOFOperations()) || [];
  } else operations = _.cloneDeep(props.of.operations);

  localOperations.value = mapArrayWithOpSequenceAsIndex(operations);
  originalOperations.value = mapArrayWithOpSequenceAsIndex(operations);
  selectedOperations.value = Array.from(
    localOperations.value,
    ({op_id}) => op_id,
  );
}

onMounted(initializeLocalData);
</script>

<template>
  <Modal
    :title="modalTitle"
    :is-operation-ongoing="saving"
    :can-confirm-modal="canConfirmModal"
    class="sub-of-modal"
    width="800px"
    dense
    is-persistent
    @confirm-modal="confirmModal"
    @close-modal="() => emit('close-modal')"
  >
    <div class="sub-of-modal__content-wrapper">
      <div class="d-flex flex-column gap-4">
        <h4>
          {{ t("SubOFModal.of_new_quantity", {ofID: ofID}) }}
        </h4>

        <div class="fd-flex-center gap-8">
          <FTextField
            :outlined="['newLayerBackground']"
            :model-value="newOFQuantity"
            :pink-border="newOFQuantity > ofQuantiteOF"
            @update:model-value="
              (v) => (newOFQuantity = getTextFieldNumericalUpdate(v))
            "
          />
          <b> | {{ ofQuantiteOF }} {{ getOFUnit(of) }} </b>
        </div>

        <span
          v-if="newOFQuantity > ofQuantiteOF"
          class="text-newPinkRegular fd-flex-center gap-4"
        >
          <vue-feather type="alert-circle" size="18px" />
          {{ t("SubOFModal.error_of_new_quantity") }}
        </span>
      </div>

      <section class="sub-of-modal__table-section">
        <div class="d-flex gap-12">
          <div class="d-flex flex-column gap-4 flex-1">
            <h4>
              {{
                t("SubOFModal.sub_of_new_quantity", {subOFID: subOFIdentifier})
              }}
            </h4>

            <div class="fd-flex-center gap-8">
              <FTextField
                :outlined="['newLayerBackground']"
                :model-value="newSubOFQuantity"
                :pink-border="quantitiesSumDelta !== 0"
                @update:model-value="
                  (v) => (newSubOFQuantity = getTextFieldNumericalUpdate(v))
                "
              >
              </FTextField>
              <b> | {{ ofQuantiteOF }} {{ getOFUnit(of) }} </b>
            </div>

            <span
              v-if="quantitiesSumDelta !== 0"
              class="sub-of-modal__error-sub-of-new-quantity"
            >
              <vue-feather
                type="alert-circle"
                size="18px"
                style="flex: 0 0 18px"
              />
              {{
                quantitiesSumDelta > 0
                  ? t("SubOFModal.error_sub_of_new_quantity_greater")
                  : t("SubOFModal.error_sub_of_new_quantity_lower")
              }}
            </span>
          </div>

          <OperationTags
            :operation="{tags: addedTags}"
            :class="{
              'pb-12': quantitiesSumDelta !== 0,
            }"
            class="sub-of-modal__tags"
            addable
            hide-status
            @add="addTags"
          />
        </div>

        <div>
          <h4>
            {{ t("SubOFModal.sub_of_table_title", {ofID: of.of_id}) }}
          </h4>

          <SubOFTable
            :operations="sortedOperations"
            :selected-operations="selectedOperations"
            :is-update="!!of.is_update"
            class="my-4"
            @remove-operation="removeRow"
            @update-operations="updateRows"
            @update-operation-order="updateOperationOrder"
            @update-selected-operations="(ops) => (selectedOperations = ops)"
          />

          <FButton icon="plus" filled @click="addOperation">
            {{ t("SubOFModal.add_operation_button") }}
          </FButton>
        </div>
      </section>
    </div>

    <template v-if="of.is_update" #prepend-actions>
      <u class="text-newPinkRegular cursor-pointer" @click="deleteSubOF">
        {{ t("SubOFModal.delete_sub_of_text") }}
      </u>

      <v-spacer />
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.sub-of-modal {
  color: rgb(var(--v-theme-noir));

  & .f-text-field__wrapper {
    flex: 0 0 230px;
    max-width: 230px;
  }

  & b {
    flex: 0 0 auto;
  }

  & h4 {
    font-weight: bold;
  }

  & .sub-of-modal__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--g-modal-spacing);
  }

  & .sub-of-modal__table-section {
    background: rgb(var(--v-theme-newSubBackground));
    padding: var(--g-modal-spacing);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: 1px solid rgb(var(--v-theme-newPrimaryRegular));
  }

  & .sub-of-modal__tags {
    flex: 1;
    align-self: flex-end;
    padding-bottom: 11px;
  }

  &:deep(.f-modal-container) {
    & .f-modal-header,
    & .f-modal-content-wrapper,
    & .f-modal-footer {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  & .sub-of-modal__error-sub-of-new-quantity {
    color: rgb(var(--v-theme-newPinkRegular));
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 1;
  }
}
</style>
