<script setup lang="ts">
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";
import {SectorGroup, Sector} from "@/interfaces";

interface SectorsGroupBreadcrumbsProps {
  sectorGroup: SectorGroup;
  sector: Sector;
  separator?: string;
  // if true, reverse the order between the sector group name and the sector name
  reverse?: boolean;
}

withDefaults(defineProps<SectorsGroupBreadcrumbsProps>(), {
  sectorGroup: () => ({} as SectorGroup),
  sector: () => ({} as Sector),
  separator: ">",
});

const devHelperAbsolutePosition = {left: "20px", bottom: "-8px"};

const {variables} = storeToRefs(useMainStore());
</script>

<template>
  <div :class="{reverse}" class="sectors-group-breadcrumbs">
    <div class="sectors-group-breadcrumbs__sector-group-name">
      <OpalIcon :stroke="variables.newMainText" name="sector" />

      <span class="text-ellipsis">
        {{ sectorGroup.name }}
      </span>

      <DevHelper :absolute="devHelperAbsolutePosition" mini>
        {{ sectorGroup.id }}
      </DevHelper>
    </div>

    <span class="text-newDisableText"> {{ separator }} </span>

    <div class="sectors-group-breadcrumbs__sector-name">
      <OpalIcon :stroke="variables.newMainText" name="machine" />

      <span class="text-ellipsis">
        {{ sector.secteur_name ?? sector.name }}
      </span>

      <DevHelper :absolute="devHelperAbsolutePosition" mini>
        {{ sector.id }}
      </DevHelper>
    </div>
  </div>
</template>

<style scoped lang="scss">
.sectors-group-breadcrumbs {
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 0 0 auto;
  color: rgb(var(--v-theme-newMainText));

  &.reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: inherit;
  }

  & .sectors-group-breadcrumbs__sector-name,
  & .sectors-group-breadcrumbs__sector-group-name {
    position: relative;
    min-width: 0;
  }
}
</style>
