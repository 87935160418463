<template>
  <div
    :class="['f-modal-wrapper', {'d-none': isProcessingBackground}]"
    :style="getWrapperStyle"
    @click.self="onClickOutside"
  >
    <div :class="{'persistent-enter-active': animatePersistence}">
      <div
        :class="[
          'f-modal-container',
          {dense: isDense},
          {bordered: !hideBorders},
          {actionable: !hideFooter},
          containerClass,
        ]"
        :style="getContainerStyle"
      >
        <ModalHeader
          :title="title"
          :subtitle="subtitle"
          :header-wrapper-class="headerWrapperClass"
          :hide-close-icon="hideCloseIcon"
          @close-modal="closeModal"
        >
          <template #prepend-title>
            <slot name="prepend-title" />
          </template>

          <template #subtitle>
            <slot name="subtitle" />
          </template>

          <template #append-title>
            <slot name="append-title" />
          </template>

          <template #prepend-close-icon>
            <slot name="prepend-close-icon" />
          </template>
        </ModalHeader>

        <div
          :class="[
            'f-modal-body',
            {'has-left-sidebar': $slots['left-sidebar']},
            {'has-right-sidebar': $slots['right-sidebar']},
            {'has-footer': !hideFooter},
            bodyClass,
          ]"
        >
          <div v-if="$slots['left-sidebar']" class="f-modal-left-sidebar">
            <slot name="left-sidebar" />
          </div>

          <div
            :class="[
              'f-modal-content-wrapper',
              {'keep-scrollbar padded-scrollbar': !hideScrollbar},
              contentWrapperClass,
            ]"
          >
            <div class="f-modal-content">
              <div v-if="isLoading" class="f-modal-loading-wrapper">
                <v-progress-circular
                  :color="variables.newPrimaryRegular"
                  size="48"
                  indeterminate
                />
              </div>
              <slot v-else />
            </div>
          </div>

          <div v-if="$slots['right-sidebar']" class="f-modal-right-sidebar">
            <slot name="right-sidebar" />
          </div>
        </div>

        <ModalFooter
          v-if="!hideFooter"
          v-bind="footerProps"
          :footer-wrapper-class="footerWrapperClass"
          :can-confirm-modal="canConfirmModal"
          :is-operation-ongoing="isOperationOngoing"
          @confirm-modal="onConfirmModal"
          @close-modal="closeModal"
        >
          <template #prepend-actions>
            <slot name="prepend-actions" />
          </template>
        </ModalFooter>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {PropType, defineComponent, ref} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import ModalHeader from "./ModalHeader.vue";
import ModalFooter from "./ModalFooter.vue";
import {useModal} from "@/composables/useModal";
import {Size, HTMLElementStyleObject} from "@/interfaces/global";
import {ModalFooterProps} from "@/interfaces";
import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  components: {
    ModalHeader,
    ModalFooter,
  },
  props: {
    title: {type: String, default: ""}, // title for the modal
    subtitle: {type: String, default: null}, // subtitle for the modal
    isPersistent: {type: Boolean, default: false}, // controls whether or not an outside click closes the modal
    isOperationOngoing: {type: Boolean, default: false}, // prop passed for logic within child components
    canConfirmModal: {type: Boolean, default: true}, // prop passed for logic within child components
    containerClass: {type: [String, Object, Array]},
    contentWrapperClass: {type: [String, Object, Array]},
    headerWrapperClass: {type: [String, Object, Array]},
    footerWrapperClass: {type: [String, Object, Array]},
    bodyClass: {type: [String, Object, Array]},
    isDense: {type: Boolean, default: false},
    isLoading: {type: Boolean, default: false},
    hideScrollbar: {type: Boolean, default: false},
    hideBorders: {type: Boolean, default: false},
    hideCloseIcon: {type: Boolean, default: false},
    hideFooter: {type: Boolean, default: false},
    zIndex: {type: [String, Number], default: "8"},
    width: {type: String, default: "1096px"},
    size: {type: String as PropType<Size>, default: "large"},
    /**
     * if set to true, will hide the Modal from the user and let him
     * interact with the app while the associated logic is performed
     * the Modal is just hidden but exists in the DOM : the closeModal() method
     * should be called at the end of the saving logic in the component
     * invoking Modal to trigger Modal's post-closing logic
     */
    processBackground: {type: Boolean, default: false},
    // works in conjunction with props.processBackground
    // hides the indeterminate snackbar while processing the confirm logic in the background
    hideSnackbar: {type: Boolean, default: false},
    /**
     * props for the ModalFooter, passed as an object through v-bind
     */
    footerProps: {
      type: Object as PropType<Partial<ModalFooterProps>>,
      default: () => ({}),
    },
  },
  emits: ["close-modal", "confirm-modal"],
  setup(_props, context) {
    const {closeModal, confirmModal} = useModal(context);
    const mainStore = useMainStore();
    const {freezeOverlays, variables} = storeToRefs(mainStore);

    const animatePersistence = ref(false);
    const isProcessingBackground = ref(false);
    return {
      closeModal,
      confirmModal,
      freezeOverlays,
      variables,
      animatePersistence,
      isProcessingBackground,
    };
  },
  computed: {
    /**
     * vuetify seems to be using an internal system with z-indexes that will set automatically
     * a value with a starting point (200) and increment by 1 any time an element overlayed is mounted to the DOM
     * because of that we need to be able to handle z-indexes specifically if required
     */
    getWrapperStyle(): HTMLElementStyleObject {
      const {zIndex} = this;
      return {zIndex};
    },
    getContainerStyle(): HTMLElementStyleObject {
      const {width} = this;
      return {width, "min-width": "380px"};
    },
  },
  methods: {
    // triggered when clicking on the modal background (excludes a click within the content of the modal)
    onClickOutside(): void {
      // we trigger this from click.self instead of v-click-outside because
      // we can have dialogs that goes above this component and therefore close it
      // the safeTags logic is made to prevent the closing of the modal upon a missclick outside
      const safeTags = ["INPUT", "TEXTAREA"];
      const {activeElement} = document;
      if (safeTags.includes(activeElement?.tagName)) return;

      if (!this.isPersistent) this.closeModal();
      else {
        // apply a v-dialog style effect on persistence
        this.animatePersistence = true;
        setTimeout(() => (this.animatePersistence = false), 500);
      }
    },
    onConfirmModal: _.throttle(function (this: any) {
      if (this.processBackground) {
        this.isProcessingBackground = true; // hides the Modal
        this.freezeOverlays = false; // unlocks the UI (if previously blocked e.g. EventsSidebar)
        if (!this.hideSnackbar) this.$openSnackbar({loading: true}); // show a snackbar during the "confirm" logic
      }
      this.confirmModal();
    }, 2000),
  },
  unmounted() {
    if (!this.hideSnackbar) this.$openSnackbar({loading: false});
    // ensures the overlays are not frozen upon deletion
    this.freezeOverlays = false;
  },
});
</script>

<style lang="scss">
@use "@/scss/mixins/responsive.scss" as responsive;
@use "@/scss/constants.scss" as constants;

.f-modal-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.67);
  display: flex;
  align-items: center;
  justify-content: center;

  /* TODO: shouldn't these be included within the root scss file ? */
  & h3 {
    line-height: 30px;
  }

  & .fbody-1 {
    line-height: 24px;
  }

  & .fbody-2 {
    line-height: 20px;
  }

  & .text-field-label {
    font-weight: 600;
  }
}

.f-modal-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin: auto;
  border-radius: 8px;
  background: rgb(var(--v-theme-newLayerBackground));
  text-align: left;
  animation: scale 0.2s;

  @include responsive.media-max("desktop-up") {
    max-width: 92vw;
  }

  &.bordered > div:not(:last-child) {
    border-bottom: constants.$fModalBorder;
  }

  &.dense {
    & .f-modal-header,
    & .f-modal-footer,
    & .f-modal-body > div {
      padding: calc(var(--g-modal-spacing) * 0.75);
    }

    &:not(.bordered) {
      & .f-modal-content-wrapper {
        padding-top: 0;
      }

      &.actionable .f-modal-content-wrapper {
        padding-bottom: 0;
      }
    }
  }
}

.f-modal-body {
  display: grid;
  grid-template-columns: 1fr;
  /* 200px is approximative height of modal header + modal footer */
  max-height: calc(92vh - 200px);
  overflow-y: auto;

  &:not(.has-footer) {
    max-height: calc(92vh - 110px);
  }

  & > div {
    padding: var(--g-modal-spacing);

    &.padded-scrollbar {
      /* 11px is the space reserved by the scrollbar */
      padding-right: calc(var(--g-modal-spacing) - 11px) !important;
    }
  }

  @include responsive.media-min("desktop-up") {
    &.has-right-sidebar {
      grid-template-columns: 1fr constants.$fModalSidebarWidth;
    }

    &.has-left-sidebar {
      grid-template-columns: constants.$fModalSidebarWidth 1fr;
    }

    &.has-right-sidebar.has-left-sidebar {
      grid-template-columns: constants.$fModalSidebarWidth 1fr constants.$fModalSidebarWidth;
    }
  }
}

.f-modal-content-wrapper {
  overflow-y: scroll;
  max-height: inherit;

  &.keep-scrollbar {
    &::-webkit-scrollbar {
      width: 18px !important;
    }
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      background-clip: padding-box !important;
      border-radius: 9999px !important;
      height: 50px;
    }
  }
}

.f-modal-content {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--g-modal-spacing);

  & .f-modal-loading-wrapper {
    padding: var(--g-modal-spacing);

    & .v-progress-circular {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  & > *:not(.v-progress-circular):not(:last-child) {
    position: relative;
    padding-bottom: var(--g-modal-spacing);

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: constants.$fModalBorder;
    }
  }
}

.f-modal-right-sidebar {
  padding-left: var(--g-modal-spacing);
  max-height: inherit;
  overflow-y: auto;

  @include responsive.media-min("desktop-up") {
    border-left: constants.$fModalBorder;
  }
  @include responsive.media-max("desktop-up") {
    border-top: constants.$fModalBorder;
  }
}

.f-modal-left-sidebar {
  padding-right: var(--g-modal-spacing);

  border-right: constants.$fModalBorder;
  max-height: inherit;
  overflow-y: auto;

  @include responsive.media-max("desktop-up") {
    border-bottom: constants.$fModalBorder;
  }
}
</style>
