<script setup lang="ts">
import {computed, inject, ref, watch} from "vue";
import {storeToRefs} from "pinia";
import {useI18n} from "vue-i18n";
import FButton from "@/components/Global/Homemade/Buttons/FButton.vue";
import FChip from "@/components/Global/Homemade/Commons/FChip.vue";
import SubOFModal from "@/domains/scheduling/domains/sub-of/components/SubOFModal.vue";
import {useMainStore} from "@/stores/mainStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {getOFUnit, type SchedulingTag, type SubOF} from "@oplit/shared-module";
import {OpenSnackbarFunction, SchedulingOperation} from "@/interfaces";
import {useRedirectAndOpenOF} from "@/composables/scheduling/useRedirectAndOpenOF";

interface SubOFWrapperProps {
  of: SchedulingOperation;
  edit: boolean;
}

const props = defineProps<SubOFWrapperProps>();
const emit = defineEmits<{
  (
    e: "sub-of-created",
    subOF: {
      of_id: SchedulingOperation["of_id"];
      quantite_of: SchedulingOperation["quantite_of"];
      unite_of: SchedulingOperation["unite_of"];
      operations: Record<string, unknown>[];
      tags: SchedulingOperation["tags"];
    },
  );
  (
    e: "sub-of-updated",
    subOF: {
      of_id: SchedulingOperation["of_id"];
      quantite_of: SchedulingOperation["quantite_of"];
    },
  );
  (e: "sub-of-deleted", subOfId: SchedulingOperation["of_id"]);
  (e: "add-tag", tag: SchedulingTag);
  (e: "quantite-of-updated", quantiteOF: number);
  (e: "close-modal");
}>();

const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");

const {redirectAndOpenOF} = useRedirectAndOpenOF();
const {t} = useI18n();
const {apiClient, variables} = storeToRefs(useMainStore());
const {selectedSimulation} = storeToRefs(useSchedulingStore());

const subOF = ref<
  | SchedulingOperation
  | (SubOF & {
      parent_quantite_of?: SubOF["quantite_of"];
      parent_of_id?: SubOF["of_id"];
      is_update?: boolean;
    })
>(null);
const isToggled = ref(false);

const linkedOFs = computed<(SubOF & {is_parent?: boolean})[]>(() => {
  const ofs = [];
  if (props.of.parent_of_id) {
    ofs.push({
      of_id: props.of.parent_of_id,
      is_parent: true,
    });
  }
  ofs.push(...(props.of.sub_ofs || []));
  return ofs;
});

async function openSubOFModal(of?: SchedulingOperation) {
  if (props.edit) {
    const [error, result] = await apiClient.value.getQuantiteOF({
      of_id: props.of.parent_of_id,
      simulation_id: selectedSimulation.value.id,
    });

    if (error) {
      openSnackbar({
        type: "negative",
        message: t("SubOFWrapper.error_loading_parent_quantite_of"),
        timeout: 2000,
      });

      return;
    }

    /**
     * additional keys used for updating values in a sub-OF
     */
    subOF.value = {
      ...of,
      parent_quantite_of: result.quantite_of || 0,
      is_update: true,
    };
  } else subOF.value = props.of;
}

function onCloseModal() {
  emit("close-modal");
  subOF.value = null;
}

watch(
  () => props.edit,
  (isEdit) => {
    if (!isEdit) return;
    openSubOFModal(props.of);
  },
);
</script>

<template>
  <div class="sub-of-wrapper">
    <FButton
      icon="plus"
      class="sub-of-wrapper__modal-button"
      icon-size="12px"
      filled
      rounded
      small
      @click="() => openSubOFModal()"
    >
      {{ t("SubOFWrapper.create_sub_of_button") }}
    </FButton>

    <div
      v-if="linkedOFs.length > 0"
      :class="{'is-toggled': isToggled}"
      class="sub-of-wrapper__container"
    >
      <FChip
        :color="variables.newLightGrey"
        :append-icon="isToggled ? 'chevron-up' : 'chevron-down'"
        prepend-icon="link"
        icon-size="12px"
        class="text-newSubText font-weight-bold"
        @click="() => (isToggled = !isToggled)"
      >
        {{ t("SubOFWrapper.associated_sub_of", {count: linkedOFs.length}) }}
      </FChip>

      <template v-if="isToggled">
        <div
          v-for="of in linkedOFs"
          :key="of.of_id"
          class="sub-of-wrapper__sub-of-row"
        >
          <span
            :class="{'font-weight-bold': of.is_parent}"
            class="text-ellipsis"
          >
            {{ of.of_id }}
          </span>

          <span v-if="!of.is_parent" class="text-newDisableText">
            {{ of.quantite_of || 0 }} {{ getOFUnit(of) }}
          </span>

          <v-spacer />

          <div class="fd-flex-center gap-4">
            <vue-feather
              type="arrow-right"
              size="14px"
              @click="() => redirectAndOpenOF(of.of_id)"
            />
          </div>
        </div>
      </template>
    </div>
  </div>

  <SubOFModal
    v-if="subOF"
    :of="subOF"
    @sub-of-created="(subOF) => emit('sub-of-created', subOF)"
    @sub-of-updated="(subOF) => emit('sub-of-updated', subOF)"
    @sub-of-deleted="(subOFID) => emit('sub-of-deleted', subOFID)"
    @quantite-of-updated="(q) => emit('quantite-of-updated', q)"
    @close-modal="onCloseModal"
    @add-tag="(tag) => emit('add-tag', tag)"
  />
</template>

<style scoped lang="scss">
.sub-of-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & .sub-of-wrapper__modal-button {
    align-self: flex-start;
    font-size: 12px;
    line-height: 1;

    &:deep(.button-text) {
      gap: 4px !important;
    }
  }

  & .sub-of-wrapper__container {
    width: 100%;
    background: rgb(var(--v-theme-blanc));
    border: 1px solid rgb(var(--v-theme-newSelected));
    border-radius: 4px;
    padding: 8px;

    &.is-toggled > :nth-child(2) {
      padding-top: 8px;
      margin-top: 8px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background: rgb(var(--v-theme-newSelected));
      }
    }

    & .sub-of-wrapper__sub-of-row {
      position: relative;
      display: flex;
      gap: 4px;
      align-items: center;
      cursor: pointer;

      & > span:first-child {
        width: 100px;
      }

      &:deep(i) {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
