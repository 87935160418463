<script setup lang="ts">
import {onMounted, ref} from "vue";
import FluxElement from "@/domains/scheduling/domains/of-sidebar/components/FluxElement.vue";
import {SchedulingOperation} from "@/interfaces";

interface FluxProps {
  selectedOp?: SchedulingOperation;
  propagateOpsChanges?: (
    updates: {[key: string]: any; op_id: string; firestore_id: string}[],
  ) => unknown;
  loading?: boolean;
  operationsList?: SchedulingOperation[];
  zIndex?: number;
  readOnly?: boolean;
}
const props = withDefaults(defineProps<FluxProps>(), {
  selectedOp: () => ({} as SchedulingOperation),
  readOnly: false,
});

const newSelectedOp = ref<SchedulingOperation>(null);
const initialOp = ref<SchedulingOperation>({} as SchedulingOperation);

onMounted(() => {
  initialOp.value = props.selectedOp;
});

function selectOp(op: SchedulingOperation) {
  newSelectedOp.value = op;
}
function isSelectedOperation(op: SchedulingOperation) {
  if (!newSelectedOp.value) return props.selectedOp.op_id === op.op_id;
  if (newSelectedOp.value.op_id === op.op_id) return true;
  /**
   * operations in the OFSidebar are grouped by their sequence, therefore there may be
   * disparities between the `op_id`s of operations of the component used to opened the OFSidebar and
   * the operations within the OFSidebar itself\
   * we use the `raw_op_id` to be able to perform the selected status-related logic
   */
  if (!newSelectedOp.value.raw_op_id) return false;
  return newSelectedOp.value?.raw_op_id === op.raw_op_id;
}
</script>

<template>
  <div class="flux-component">
    <div class="d-flex" v-if="loading">
      <v-progress-circular class="ma-auto mt-5" indeterminate color="primary" />
    </div>
    <v-timeline v-else density="comfortable" side="end">
      <FluxElement
        v-for="(op, index) in operationsList"
        :key="`${index}`"
        :op="op"
        :is-selected="isSelectedOperation(op)"
        :select-op="selectOp"
        :selected-op-id="((newSelectedOp?.op_id || selectedOp?.op_id) as string)"
        :initial-op="initialOp"
        :propagate-ops-changes="propagateOpsChanges"
        :z-index="zIndex"
        :read-only="readOnly"
        :index="index"
      />
    </v-timeline>
  </div>
</template>

<style scoped lang="scss">
.flux-component {
  :deep(.selected-op) {
    & .v-timeline-divider > div {
      background-color: rgb(var(--v-theme-newPrimaryRegular));

      & > div {
        background-color: inherit !important;
      }
    }

    & .v-timeline-divider,
    & .v-timeline-item__body,
    & .v-timeline-item__opposite {
      background: rgb(var(--v-theme-newPrimaryLight2));
    }

    & .v-timeline-item__body {
      padding-top: 12px;
      padding-bottom: 16px;
    }

    & .v-timeline-item__opposite {
      height: 100%;
    }
  }

  :deep(.v-timeline-item__body) {
    width: 100%;
  }

  .v-timeline--dense:not(.v-timeline--reverse)::before {
    left: 21px;
    background: rgb(var(--v-theme-newSelected));
  }
  .v-timeline::before {
    top: 25px;
    height: calc(100% - 69px);
    width: 1px;
  }
  .v-text-field.v-text-field--enclosed {
    background: rgb(var(--v-theme-blanc));
    max-width: 146px;
  }
  .text-field--disabled fieldset {
    border: 1px solid rgb(var(--v-theme-newSelected)) !important;
    background: rgb(var(--v-theme-blanc));
  }
  .button.input-button {
    background-color: rgb(var(--v-theme-blanc)) !important;
    min-width: 146px;
  }
  .button.v-btn.v-btn--outlined.v-btn--text:hover {
    background-color: rgb(var(--v-theme-blanc)) !important;
  }
  .done-task {
    .v-timeline-item__inner-dot {
      background-color: transparent !important;
      border: 1px solid gray;
    }
  }
}
</style>
