import {FieldValue} from "firebase/firestore";
import {
  ImportDispatchFormula,
  ImportDispatchFormulaConjunction,
  ImportDispatchFormulaOperand,
  ImportDispatchFormulaOperation,
  ImportParsingFields,
  ImportParsingParameters,
  Mesh,
  ParametersSchedulingRuleAIData,
} from "@oplit/shared-module";
import {
  Sector,
  MSDField,
  OplitFirestoreDocument,
  VersionedOplitFirestoreDocument,
} from "@/interfaces";

// FIXME: more generic than the parameters module : place in correct file

interface ParametersAppMappingObject {
  excel_name: string;
  oplit_name: string;
}

interface ParametersColorsCategoryOption {
  name: string;
  color_name: string; // color name from the DS e.g. "newPrimaryRegular"
}

interface ParametersColorsCategory extends ParametersAppMappingObject {
  id: string;
  client_id: string;
  team_id?: string;
  randomize: boolean; // should the colors be randomized
  is_scheduling: boolean; // whether or not the current category is for scheduling
  options: ParametersColorsCategoryOption[];
}

interface ParametersColorsCategoryOption {
  name: string;
  color_name: string; // color name from the DS e.g. "newPrimaryRegular"
}

interface ParametersColorsCategory extends ParametersAppMappingObject {
  id: string;
  client_id: string;
  team_id?: string;
  randomize: boolean; // should the colors be randomized
  is_scheduling: boolean; // whether or not the current category is for scheduling
  options: ParametersColorsCategoryOption[];
}

interface SimulationPeriod {
  temporal_scale?: Mesh;
  period?: "current" | "last";
}

interface ParametersHomepageUserSection {
  perimeters_ids?: string[];
  simulation_period?: SimulationPeriod;
}

interface ParametersHomepageUser {
  id?: string;
  client_id?: string;
  user_id?: string;
  last_version_read?: string;
  display_settings?: {
    planning?: boolean;
    scheduling?: boolean;
    piloting?: boolean;
  };
  planning?: ParametersHomepageUserSection;
  scheduling?: ParametersHomepageUserSection;
  piloting?: ParametersHomepageUserSection;
}

interface ImportRule {
  id?: string;
  client_id?: string;
  collection?: string;
  created_by?: string;
  updated_by?: string;
  created_at?: FieldValue;
  updated_at?: FieldValue;
  rules?: DispatchFormula[];
  fallback_sector_id?: string;
  target_sector_id?: string;
  status?: string;
  new_rule?: boolean;
}

type DispatchFormula = ImportDispatchFormula;

type FormulaOperation = ImportDispatchFormulaOperation;

type FormulaConjunction = ImportDispatchFormulaConjunction;

type FormulaOperand = ImportDispatchFormulaOperand;

interface IApi_connectors {
  password: string;
  api_url: string;
  api_key: string;
  site_id: string;
  type: string;
  user: string;
  client_id: string;
}
interface Parameters {
  objectiveDeliveredOnTime: number;
  classic_view: boolean;
  analytics_adhesionLag: number;
  unique_scheduling_simulation: boolean;
  has_module_stocks: boolean;
  analytics_objectiveStartedOnTime: number;
  display_parent_text: false;
  import_types: [];
  updated_at: string;
  pg_parsed: string;
  fabriq_to_oplit: boolean;
  displayed_graphs: string[];
  planifPieces: true;
  pg_scheduling: true;
  keep_advance: false;
  api_connectors: IApi_connectors[];
  analytics_objectiveDeliveredOnTime: number;
  auto_orga: true;
  nbPeriodePrev: number;
  client_id: string;
  scheduling_display_tree: boolean;
  analytics_objectiveMeanDelayOFs: number;
  analytics_interval: number;
  floating_simulation: boolean;
  has_module_scheduling: boolean;
  action_in_fabriq: boolean;
  analytics_showConsolidated: boolean;
  has_module_pdp: boolean;
  scheduling_ignore_machines: boolean;
  objectiveStartedOnTime: number;
  use_is_late: boolean;
  gantt_view: boolean;
  use_merca_planning: boolean;
  graph_period_checkbox: boolean;
  id: string;
  pg_data: true;
  analytics_objectiveDelay: number;
  pg_sse: ["planning", "charge", "simulation", "capa", "scheduling"];
  analytics_minDays: number;
  nbPeriodeHisto: number;
  analytics_showDetailed: boolean;
  auto_archive_ticket: boolean;
  list_view: boolean;
  maille: string;
}

interface MsdParameter extends Sector {
  all_site_params?: boolean;
  start_date?: string;
  msd_id?: string;
  fields?: MSDField[];
}

type ParametersCalculusRulesFormulaChipsChipType =
  | "operation"
  | "hourMode"
  | "parameter"
  | "child";

interface ShiftPlanningItem {
  name: string;
  start_time: string;
  values: [number, number, number, number, number, number, number];
}
interface ShiftPlanningDocument extends OplitFirestoreDocument {
  shift_planning: ShiftPlanningItem[];
}

type FormulaTag = {
  label: string;
  model: string;
};

type ParsingImportTypeField = ImportParsingFields;

type ParsingRulesData = Partial<VersionedOplitFirestoreDocument> &
  ImportParsingParameters;

interface ParametersSchedulingRulesPriorityItem {
  value: string;
  label: string;
  in_formula?: boolean;
}

interface ParametersSchedulingRulesCustomDirection {
  order: number;
  value: string;
}

interface MSD extends OplitFirestoreDocument {
  name?: string;
  pg_parsed?: string;
  start_date?: string;
  end_date?: string;
}

interface ParametersSchedulingRule {
  id: OplitFirestoreDocument["id"];
  client_id: OplitFirestoreDocument["client_id"];
  msd_id: string;
  name?: string;
  ai_data?: ParametersSchedulingRuleAIData;
}

type ParametersRule = OplitCalculusRule | ParametersSchedulingRule;
interface ClientGroup extends OplitFirestoreDocument {
  name: string;
}

type OplitCalculusRule = {
  client_id?: string;
  formula?: OplitCalculusFormula;
  id?: string;
  msd_id?: string;
  name?: string;
};

type OplitCalculusFormulaItemType =
  | "field"
  | "hourMode" // defined values are `default` and `planning`
  | "operation"
  | "children" // when the aggregation method is set to a regular one for a not-lowest-level sector
  | "child" // specific when having a custom formula (aggregation_method === 'other') for a not-lowest-level sector
  | "parenthese_ouvrante"
  | "parenthese_fermante"
  | "function"
  | "constant"; // `constant` is the type for free user input
type OplitCalculusFormulaItem<T = MSDField["value"]> = Omit<
  MSDField,
  "type" | "value"
> & {
  type: OplitCalculusFormulaItemType;
  value?: T;
  secteur_id?: string;
};
type OplitCalculusFormula = OplitCalculusFormulaItem[];

enum UserParametersFavorites {
  Sectors = "favoritesSectors",
  SectorsGroups = "favoritesSectorsGroups",
}

export * from "./permissions_by_role";
export {
  ParametersAppMappingObject,
  ParametersColorsCategory,
  ParametersColorsCategoryOption,
  SimulationPeriod,
  ParametersHomepageUserSection,
  ParametersHomepageUser,
  ImportRule,
  DispatchFormula,
  FormulaOperation,
  FormulaConjunction,
  FormulaOperand,
  Parameters,
  ParametersCalculusRulesFormulaChipsChipType,
  MsdParameter,
  ShiftPlanningItem,
  ShiftPlanningDocument,
  FormulaTag,
  ParsingImportTypeField,
  ParsingRulesData,
  ParametersSchedulingRulesPriorityItem,
  ParametersSchedulingRulesCustomDirection,
  MSD,
  OplitCalculusRule,
  OplitCalculusFormula,
  OplitCalculusFormulaItem,
  OplitCalculusFormulaItemType,
  ParametersSchedulingRule,
  ParametersRule,
  ClientGroup,
  UserParametersFavorites,
};
