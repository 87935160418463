import numeral from "numeral";
numeral.locale("fr");
import moment from "moment";
import {TimestampObject, parseDate} from "@oplit/shared-module";
import {TAG_SEPARATOR} from "@/config/constants";

const assets = import.meta.glob<Record<string, string>>("../../assets/**/*", {
  eager: true,
  query: "?url",
});

/**
 * This file could be/is temporary and will contain either general utility functions
 * or functions that should be placed in the correct helper file
 * ~ Nicolas B (04/14/22 - collab start)
 */

/**
 * @param entity: object | object (supposedly) with "first_name"/"last_name" keys
 * @param optionalFirstNameKey: string | key storing the "first name" of the entity (opt.)
 * @param optionalLastNameKey: string | key storing the "last name" of the entity (opt.)
 * @returns: string | the full name of the entity
 */
const fullName = (
  entity: {first_name: string; last_name: string},
  optionalFirstNameKey?: string,
  optionalLastNameKey?: string,
): string => {
  const keyFirstName = optionalFirstNameKey || "first_name";
  const keyLastName = optionalLastNameKey || "last_name";

  return entity
    ? [entity[keyFirstName], entity[keyLastName]].filter(Boolean).join(" ")
    : "?";
};

/**
 * @param tsObject: object | timestamp formatted as { seconds, nanoseconds }
 * @returns: number | the timestamp (with ms) as a number
 */
/*
const timestampFromTimestampObject = (tsObject: { seconds: number | string, nanoseconds: number | string }) => Number(`${tsObject.seconds}${tsObject.nanoseconds}`.slice(0, 13))
*/

/**
 * Transforms a DEFAULTY-FORMATTED ("0,0.[00]") numeral string into a number
 */
const reversePrettified = (stringifiedNumeric: string): number => {
  if (!stringifiedNumeric.match(/^\d+,\d+(\s\d+)?$/))
    return +stringifiedNumeric;
  return +stringifiedNumeric.replace(/\s/g, "").replace(/,/g, ".");
};

const prettifyNumber = (num: number | string, format?: string): string =>
  numeral(+num || 0).format(format || "0,0.[00]");

const getInitialLetter = (str: string, toUpperCase = false): string => {
  return str
    ? toUpperCase
      ? `${str}`.charAt(0).toUpperCase()
      : `${str}`.charAt(0)
    : "";
};

const fixNumber = (num: number | string, decimals = 2) =>
  +(+num).toFixed(decimals);

/***
 * returns a finite numerical value from an @unknownValue or returns @fallbackNumericalValue otherwise
 * @unknownValue the value to convert to a number
 * @fallbackNumericalValue the value to return if `unknownValue` cannot be converted to a number
 * - handles NaN & infinite cases
 * - replaces `,` with `.` in string values
 * @alias numerizeUnknownValue
 */
const n = function numerizeUnknownValue(
  unknownValue: unknown,
  fallbackNumericalValue = 0,
  additionalParams?: {
    decimals: number;
  },
) {
  const {decimals = 2} = additionalParams || {};
  if (Number.isNaN(unknownValue)) return fallbackNumericalValue;
  if (unknownValue === Infinity) return fallbackNumericalValue;
  if (typeof unknownValue === "number")
    return fixNumber(unknownValue, decimals);
  if (typeof unknownValue === "string") {
    return n(
      +unknownValue.replace(",", "."),
      fallbackNumericalValue,
      additionalParams,
    );
  }
  return fallbackNumericalValue;
};

// returns a string representing the style for a chip (colored circular div)
// FIXME: maybe store elsewhere ?
const getHTMLSafeChipStyle = (
  backgroundColor: string,
  chipSize = "8px",
): string =>
  Array.from(
    [
      ["background", backgroundColor],
      ["width", chipSize],
      ["height", chipSize],
      ["border-radius", "50%"],
    ],
    (styleArray) => styleArray.join(": "),
  ).join(";");

/**
 * returns an object from an array, with the key being equal to the array member
 * e.g. {foo: "foo"}
 */
const optionize = (array: string[]): Record<string, string> =>
  Object.fromEntries(Array.from(array, (key: string) => [key, key]));

/**
 * returns an asset from the `assets` folder for usage in HTML
 * this way of loading an asset is required for dynamic display
 */
function getAssetURL(...pathMembers: string[]): string {
  const path = `../../assets/${pathMembers.join("/")}`;

  return assets[path].default;
}

// returns a human-readable version of the "sent at" timestamp for the message
const displayedTimestamp = (timestampObject: TimestampObject): string => {
  const dateObject = parseDate(timestampObject);
  return moment.utc(dateObject).fromNow();
};

function getSortedDropdownOptionsList<T = (string | Record<string, unknown>)[]>(
  options: T[],
  key?: string,
): T[] {
  return options.sort((optionA, optionB) => {
    const firstMember: unknown = key ? optionA[key] : optionA;
    const secondMember: unknown = key ? optionB[key] : optionB;
    /**
     * we cast the names as string because some entities name aren't necessarily stored as string
     */
    return `${firstMember}`.localeCompare(`${secondMember}`);
  });
}

function replaceTagSeparator(str: string): string {
  if (!str) return "";
  return `${str}`.replace(TAG_SEPARATOR, " - ");
}

export {
  fullName,
  prettifyNumber,
  fixNumber,
  n,
  getHTMLSafeChipStyle,
  optionize,
  displayedTimestamp,
  reversePrettified,
  getInitialLetter,
  getSortedDropdownOptionsList,
  getAssetURL,
  replaceTagSeparator,
};
