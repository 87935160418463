<script setup lang="ts">
import {computed} from "vue";
import {storeToRefs} from "pinia";
import FCheck from "@/components/Global/Homemade/Controls/FCheck.vue";
import FTextField from "@/components/Global/Homemade/Inputs/FTextField.vue";
import FDropdown from "@/components/Global/Homemade/Dropdowns/FDropdown.vue";
import FDatePicker from "@/components/Global/FDatePicker.vue";
import {useMainStore} from "@/stores/mainStore";
import {SchedulingOperation} from "@/interfaces";

interface SubOFTableRowProps {
  operation: SchedulingOperation;
  selectedOperations: SubOFTableRowProps["operation"]["op_id"][];
  headers: {label: string; field: string; icon?: string}[];
  isUpdate: boolean;
}

const props = defineProps<SubOFTableRowProps>();
const emit = defineEmits<{
  (e: "update-operation", {field, value}: {field: string; value: string});
  (e: "remove-operation");
  (e: "select-operation");
  (e: "update-operation-order", newOrder: number);
}>();

const {machines, stations, variables} = storeToRefs(useMainStore());

const isSelectedOperation = computed(() =>
  props.selectedOperations.includes(props.operation.op_id),
);
const directions: ("up" | "down")[] = ["up", "down"];

function getDisplayedValue(field: string) {
  const value = props.operation[field] as string;

  switch (field) {
    case "secteur_id": {
      // some sectors/machines have their names stored as numbers : we cast to string to match the `fieldText` prop definition
      const station = stations.value.find(({id}) => id === value);
      if (station) return `${station.name}`;
      const machine = machines.value.find(({id}) => id === value);
      if (machine) return `${machine.name}`;
      return value;
    }
    default:
      return value;
  }
}
function updateOperation(field: string, value: string) {
  emit("update-operation", {field, value});
}
function shouldDisplayTextField(field: string): boolean {
  if (field === "op_name") return props.operation.is_custom || props.isUpdate;
  return field === "quantite";
}
function shouldDisplaySectorDropdown(field: string): boolean {
  if (field !== "secteur_id") return false;
  return props.operation.is_custom || props.isUpdate;
}
function isDisabledOrderArrow(direction: "up" | "down") {
  if (direction === "up") return props.operation.op_sequence === 1;
  else return +props.operation.op_sequence === props.operation.operationsLength;
}
function getArrowStrokeColor(direction: "up" | "down") {
  if (isDisabledOrderArrow(direction)) return variables.value.newDisableBG;
  return variables.value.newSubText;
}
function updateOperationOrder(direction: "up" | "down") {
  if (isDisabledOrderArrow(direction)) return;

  emit(
    "update-operation-order",
    +props.operation.op_sequence + (direction === "up" ? -1 : 1),
  );
}
function removeOperation() {
  if (!props.operation.is_custom) return;
  emit("remove-operation");
}
</script>

<template>
  <div class="sub-of-table-row">
    <template v-for="{field} of headers" :key="field">
      <FCheck
        v-if="field === 'select'"
        :model-value="isSelectedOperation"
        @update:model-value="() => emit('select-operation')"
      />

      <FTextField
        v-else-if="shouldDisplayTextField(field)"
        :model-value="getDisplayedValue(field)"
        :outlined="['newLayerBackground']"
        @update:model-value="(value) => updateOperation(field, value)"
      />

      <FDatePicker
        v-else-if="field === 'day_date'"
        :date-value="operation[field]"
        :handle-change="(date) => updateOperation('day_date', date)"
        class="sub-of-table-row__field-day-date"
      />

      <vue-feather
        v-else-if="field === 'actions'"
        :stroke="
          variables[operation.is_custom ? 'newPinkRegular' : 'newSubBackground']
        "
        :class="{'cursor-pointer': operation.is_custom}"
        type="trash-2"
        size="16px"
        class="sub-of-table-row__field-actions"
        @click="removeOperation"
      />

      <FDropdown
        v-else-if="shouldDisplaySectorDropdown(field)"
        :model-value="operation[field]"
        :field-text="getDisplayedValue(field) as string"
        :tooltip="getDisplayedValue(field) as string"
        :outlined="['newLayerBackground']"
        :on-change="
          ({id}) => emit('update-operation', {field: 'secteur_id', value: id})
        "
        type="perimeter"
        content-class="sub-of-table-row__perimeters-dropdown"
        btn-class="text-ellipsis"
        text-class="text-ellipsis"
        item-value="id"
        display-machine-tags
      >
        <template #content="{text}">
          <span class="text-ellipsis">
            {{ text }}
          </span>
        </template>
      </FDropdown>

      <div v-else-if="field === 'index'" class="fd-flex-center">
        <vue-feather
          v-for="direction in directions"
          :type="`arrow-${direction}`"
          :key="`direction-${direction}`"
          :stroke="getArrowStrokeColor(direction)"
          size="16px"
          :class="
            isDisabledOrderArrow(direction)
              ? 'cursor-not-allowed'
              : 'cursor-pointer'
          "
          @click="() => updateOperationOrder(direction)"
        >
        </vue-feather>
      </div>

      <span
        v-else
        v-tooltip="getDisplayedValue(field)"
        class="text-ellipsis flex-1"
      >
        {{ getDisplayedValue(field) }}
      </span>
    </template>
  </div>
</template>

<style scoped lang="scss">
@use "@/scss/mixins/sub-of-table.scss" as sot;

@include sot.sub-of-table(".sub-of-table-row");

.sub-of-table-row {
  &:deep(.f-check) {
    padding: 0;
  }

  & .sub-of-table-row__field-day-date:deep(.v-input) {
    background: rgb(var(--v-theme-newLayerBackground));
  }

  & .sub-of-table-row__field-actions {
    justify-self: center;
  }

  // fixes text overflowing within FDropdown
  :deep(.button-text) {
    max-width: calc(100% - var(--default-chevron-size));

    & > div {
      max-width: 100%;
    }
  }
}
</style>
<style lang="scss">
.sub-of-table-row__perimeters-dropdown {
  min-width: 250px !important;
}
</style>
