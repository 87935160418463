<script setup lang="ts">
import {computed} from "vue";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";
import {UserParametersFavorites} from "@/domains/parameters/types";
import {TEST_IDS} from "@/config/constants";

/**
 * @parametersKey key in the collection `parametres_user` where the value (`entityId`) is stored in a strings array
 * @entityId string value stored in the array
 */
interface FavoriteWrapperProps {
  parametersKey: UserParametersFavorites;
  entityId: string;
  doNotCenterContent?: boolean;
  doNotRenderIcon?: boolean;
  forceIconVisibility?: boolean;
}

const props = defineProps<FavoriteWrapperProps>();

const {userParameters, userData} = storeToRefs(useMainStore());
const {setUserParameter} = useMainStore();

const currentUserParameter = computed<string[]>(
  () => userParameters.value[props.parametersKey] || [],
);
// FIXME: remove after migrating data from `users` to `parametres_user` (OPL-8249)
const isFavorite = computed<boolean>(() => {
  const hasFavoriteFromParametresUser = currentUserParameter.value.includes(
    props.entityId,
  );

  if (hasFavoriteFromParametresUser) return hasFavoriteFromParametresUser;

  if (props.parametersKey !== UserParametersFavorites.Sectors) return false;

  return !!userData.value?.favorites?.find(
    ({secteur_id}) => secteur_id === props.entityId,
  );
});

function setFavorite(): void {
  if (isFavorite.value) {
    setUserParameter({
      [props.parametersKey]: currentUserParameter.value.filter(
        (id) => id !== props.entityId,
      ),
    });
  } else {
    setUserParameter({
      [props.parametersKey]: [...currentUserParameter.value, props.entityId],
    });
  }
}
</script>

<template>
  <div
    :class="{
      'favorite-wrapper__is-favorite': isFavorite,
      'favorite-wrapper__force-icon-visibility': forceIconVisibility,
      'items-center': !doNotCenterContent,
    }"
    class="favorite-wrapper"
  >
    <span
      class="text-ellipsis"
      :data-testid="`${TEST_IDS.FAVORITE_WRAPPER__ENTITY_PREFIX}${props.entityId}`"
    >
      <slot />
    </span>

    <OpalIcon
      v-if="!doNotRenderIcon"
      name="star"
      clickable
      @click.stop="() => setFavorite()"
    />
  </div>
</template>

<style scoped lang="scss">
.favorite-wrapper {
  --star-color: rgb(var(--v-theme-yellow-300));

  display: flex;
  gap: 8px;

  & svg {
    fill: white;
    stroke: rgb(var(--v-theme-grey-700));
  }

  // properly aligning default slot content and icon when alignment is start
  &:not(.items-center) i {
    margin-top: 2px;
  }

  &:not(.favorite-wrapper__is-favorite) {
    &:not(.favorite-wrapper__force-icon-visibility) i[data-icon="star"] {
      opacity: 0;
    }

    &:hover i[data-icon="star"] {
      opacity: 1;

      &:deep(svg) {
        fill: rgb(var(--v-theme-white));
        stroke: rgb(var(--v-theme-grey-700));

        &:hover {
          fill: var(--star-color);
          stroke: var(--star-color);
        }
      }
    }
  }

  &.favorite-wrapper__is-favorite {
    & i[data-icon="star"] :deep(svg) {
      fill: var(--star-color);
      stroke: var(--star-color);
    }
  }
}
</style>
