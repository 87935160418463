<script setup lang="ts">
import {computed, getCurrentInstance} from "vue";
import OperationsRightBar from "@/domains/scheduling/domains/of-sidebar/components/OperationsRightBar.vue";
import type {OperationsRightBarProps, SchedulingOperation} from "@/interfaces";

const props = withDefaults(
  defineProps<{
    operation: SchedulingOperation;
    sidebarProps: OperationsRightBarProps;
  }>(),
  {sidebarProps: () => ({})},
);

const instance = getCurrentInstance();

const finalSidebarProps = computed(() => ({
  op: props.operation,
  closeSidebar: onClickOutside,
  ...props.sidebarProps,
}));

function onClickOutside(): void {
  // remove the wrapper element from the DOM
  if (typeof instance.attrs.id === "string")
    document.getElementById(instance.attrs.id)?.remove();
}

/**
 * we cannot use the v-click-outside otherwise a click on any menu opened
 * within the OperationsRightBar component would dismiss the sidebar
 */
function onOverlayClick(event: MouseEvent): void {
  if (!event) return;
  const target = event.target as HTMLDivElement;
  if (target.className?.includes?.("of-sidebar__wrapper"))
    return onClickOutside();
}
</script>

<template>
  <div class="of-sidebar__wrapper" @click="onOverlayClick">
    <div class="of-sidebar__container">
      <OperationsRightBar v-bind="finalSidebarProps" />
    </div>
  </div>
</template>

<style lang="scss">
.of-sidebar__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 33, 33, 0.46);
}

.of-sidebar__container {
  position: fixed;
  top: 0;
  right: 0;
  width: 528px;
  height: 100%;
  background: rgb(var(--v-theme-newLayerBackground));
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  border-radius: 8px 0 0 8px;
}
</style>
