import {RouteRecordRaw} from "vue-router";
import {storeToRefs} from "pinia";
import {unref} from "vue";
import {
  DEFAULT_PARAMETERS_BY_SECTOR_ROUTE,
  DEFAULT_PARAMETERS_GENERAL_ROUTE,
  PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING,
  PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING,
  PARAMETERS_ROOT_ROUTE_NAME,
  PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING,
  DEFAULT_PARAMETERS_IMPORTS_ROUTE,
} from "@/router/constants/parameters";
import {DEFAULT_ANALTYICS_ROUTE} from "@/router/constants/analytics";
import {SCHEDULING_ROUTES_NAMES_MAPPING} from "@/router/constants/scheduling";
import {useMainStore} from "@/stores/mainStore";
import {usePermissionsStore} from "@/lib/stores/permissionsStore";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/home", // TODO verify query
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/domains/homepage/views/HomePage.vue"),
    meta: {
      requiresAuth: true,
      icon: "home",
      hideTopNavbar: true,
    },
    beforeEnter: (_to: any, from: any, next: any) => {
      const {hasOnlySideModules, defaultRouteName} = storeToRefs(
        useMainStore(),
      );
      if (!unref(hasOnlySideModules)) return next();
      next({name: unref(defaultRouteName)});
    },
  },
  {
    path: "/dashboard",
    name: DEFAULT_ANALTYICS_ROUTE,
    component: () => import("@/domains/analytics/views/DashboardAnalytics.vue"),
    meta: {
      requiresAuth: true,
      icon: "grid",
      title: "Analytics",
      permissions: ["general.read_analytics"],
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/domains/administration/views/Admin.vue"),
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      icon: "monitor",
      title: "Admin",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/domains/auth/views/Login.vue"),
    meta: {hideNavigation: true},
  },
  {
    path: "/profile/:id/:redirect",
    name: "profile",
    component: () => import("@/domains/user/views/ProfileWrapper.vue"),
    meta: {
      requiresAuth: true,
      icon: "user",
      title: "Profile",
    },
    beforeEnter(to, _from, next): void {
      const permissionsStore = usePermissionsStore();

      const mainStore = useMainStore();
      const {userData, defaultRouteName} = storeToRefs(mainStore);

      if (to.params.id === userData.value?.id) return next();
      if (permissionsStore.currentPermissions.general.create_update_user)
        return next();
      next({name: unref(defaultRouteName)});
    },
  },
  {
    path: "/client/:id",
    name: "client",
    component: () => import("@/domains/administration/views/ClientWrapper.vue"),
    meta: {
      requiresAuth: true,
      icon: "user",
      title: "Client",
    },
  },
  {
    path: "/client-profile/:id/:redirect",
    name: "clientprofile",
    component: () => import("@/domains/user/views/ProfileWrapper.vue"),
    meta: {
      requiresAuth: true,
      icon: "user",
      title: "Profile",
    },
  },
  {
    path: "/simulation",
    name: "simulation",
    component: () => import("@/views/Simulation/SimulationWrapper.vue"),
    meta: {
      requiresAuth: true,
      requiresPDP: true,
      icon: "sliders",
      title: "Planification",
    },
  },
  {
    path: "/plan-action",
    name: "planaction",
    component: () => import("@/domains/action-plan/views/PlanAction.vue"),
    meta: {
      requiresAuth: true,
      icon: "check-circle",
      title: "Plan",
      permissions: ["general.create_read_update_action"],
    },
  },
  {
    path: "/scheduling",
    component: () => import("@/views/Scheduling/SchedulingWrapper.vue"),
    meta: {
      requiresAuth: true,
      requiresScheduling: true,
      icon: "crosshair",
      title: "Planning",
    },
    children: [
      {
        path: "planning",
        name: SCHEDULING_ROUTES_NAMES_MAPPING.planning,
        component: () => import("@/views/Scheduling/Planning/Planning.vue"),
        meta: {
          icon: "none",
          title: "Scheduling",
        },
      },
      {
        path: "piloting",
        name: SCHEDULING_ROUTES_NAMES_MAPPING.piloting,
        component: () =>
          import("@/domains/scheduling/domains/piloting/layouts/Piloting.vue"),
        meta: {
          icon: "none",
          title: "Ordo",
        },
      },
    ],
  },
  {
    path: "/customer-interface",
    name: "customer-interface",
    component: () =>
      import("@/domains/customer-interface/views/CustomerInterface.vue"),
    meta: {
      requiresAuth: true,
      requiresCustomerInterface: true,
      icon: "users",
      title: "Customer-interface",
    },
  },
  {
    path: "/import-file",
    name: "importfile",
    component: () =>
      import("@/domains/parameters/domains/imports/views/ImportFile.vue"),
    meta: {
      requiresAuth: true,
      icon: "database",
      title: "Import",
    },
  },
  {
    path: "/parametres",
    name: PARAMETERS_ROOT_ROUTE_NAME,
    component: () => import("@/domains/parameters/layouts/Parametres.vue"),
    meta: {
      requiresAuth: true,
      icon: "settings",
      title: "Paramètres",
      permissions: ["general.read_parameters"],
    },
    redirect: {name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.root},
    children: [
      {
        path: "general",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.root,
        redirect: {name: DEFAULT_PARAMETERS_GENERAL_ROUTE},
      },
      {
        path: "general/structure-usine",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.factoryStructure,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersFactoryStructure.vue"
          ),
      },
      {
        path: "general/groupes-secteurs",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.sectorsGroups,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersSectorsGroups.vue"
          ),
      },
      {
        path: "general/liste-operateurs",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.operatorsList,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersOperatorsList.vue"
          ),
      },
      {
        path: "general/liste-machines",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.machinesList,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersMachinesList.vue"
          ),
      },
      {
        path: "general/utilisateurs-oplit",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.oplitUsers,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersOplitUsers.vue"
          ),
      },
      {
        path: "general/liste-parametres",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.parametersList,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersParametersList.vue"
          ),
      },
      {
        path: "general/regles-calcul",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.calculusRules,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersCalculusRulesGeneral.vue"
          ),
      },
      {
        path: "general/capacite-journaliere",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.dailyCapacity,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersDailyCapacity.vue"
          ),
      },
      {
        path: "general/regles-ordonnancement",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.schedulingRules,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersSchedulingRulesGeneral.vue"
          ),
      },
      {
        path: "general/affichage-planification",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.planningDisplay,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersPlanningDisplay.vue"
          ),
      },
      {
        path: "general/affichage-ordonnancement",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.schedulingDisplay,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersSchedulingDisplay.vue"
          ),
      },
      {
        path: "general/stocks",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.stocks,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersStocks.vue"
          ),
      },
      {
        path: "general/nomenclature",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.nomenclatures,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersNomenclatures.vue"
          ),
      },
      {
        path: "general/customer-provider-display",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.customerProviderDisplay,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersCustomerProviderDisplay.vue"
          ),
      },
      {
        path: "general/oplit-date-calculation",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.oplitDateCalculation,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersOplitDateCalculation.vue"
          ),
      },
      {
        path: "general/configuration-modules",
        name: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.modules,
        component: () =>
          import(
            "@/domains/parameters/domains/general/views/ParametersConfigurationModules.vue"
          ),
      },
      {
        path: "secteur",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.root,
        redirect: {name: DEFAULT_PARAMETERS_BY_SECTOR_ROUTE},
      },
      {
        path: "secteur/regles-calcul",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.calculusRules,
        component: () =>
          import(
            "@/domains/parameters/domains/by-sector/views/ParametersCalculusRulesBySector.vue"
          ),
      },
      {
        path: "secteur/calendrier",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.calendar,
        component: () =>
          import(
            "@/domains/parameters/domains/by-sector/views/ParametersCalendar.vue"
          ),
      },
      {
        path: "secteur/regles-ordonnancement",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.schedulingRules,
        component: () =>
          import(
            "@/domains/parameters/domains/by-sector/views/ParametersSchedulingRulesBySector.vue"
          ),
      },
      {
        path: "secteur/informations",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.sectorInformation,
        component: () =>
          import(
            "@/domains/parameters/domains/by-sector/views/ParametersSectorInformation.vue"
          ),
      },
      {
        path: "secteur/planning-standard",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.standardPlanning,
        component: () =>
          import(
            "@/domains/parameters/domains/by-sector/views/ParametersStandardPlanning.vue"
          ),
      },
      {
        path: "secteur/stock-cible",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.targetStock,
        component: () =>
          import(
            "@/domains/parameters/domains/by-sector/views/ParametersTargetStock.vue"
          ),
      },
      {
        path: "secteur/encours-cible",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.targetWIP,
        component: () =>
          import(
            "@/domains/parameters/domains/by-sector/views/ParametersTargetWIP.vue"
          ),
      },
      {
        path: "secteur/load-formula",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.loadFormula,
        component: () =>
          import(
            "@/domains/parameters/domains/by-sector/views/ChargeMachines.vue"
          ),
      },
      {
        path: "secteur/load-calculus-rules",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.loadCalculusRules,
        component: () =>
          import(
            "@/domains/parameters/domains/by-sector/views/ParametersLoadCalculusRulesBySector.vue"
          ),
      },
      {
        path: "secteur/dispatch-rules",
        name: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.dispatchRules,
        component: () =>
          import(
            "@/domains/parameters/domains/by-sector/views/ParametersDispatchRules.vue"
          ),
      },
      {
        path: "import",
        name: PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.root,
        redirect: {name: DEFAULT_PARAMETERS_IMPORTS_ROUTE},
      },
      {
        path: "import/import-data",
        name: PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.importData,
        component: () =>
          import(
            "@/domains/parameters/domains/imports/views/ImportHistory.vue"
          ),
      },
      {
        path: "import/import-rules",
        name: PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.importRules,
        component: () =>
          import("@/domains/parameters/domains/imports/views/ImportRules.vue"),
      },
      {
        path: "import/import-parsing-rules",
        name: PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.importParsingRules,
        component: () =>
          import(
            "@/domains/parameters/domains/imports/views/ImportParsingRules.vue"
          ),
      },
    ],
  },
  {
    path: "/frames",
    component: () => import("@/domains/frames/layouts/Frames.vue"),
    meta: {
      requiresAuth: true,
      icon: "settings",
      title: "Frames",
    },
    children: [
      {
        path: "",
        name: "frames",
        component: () => import("@/domains/frames/layouts/Frames.vue"),
      },
      {
        path: "adhesion-rate",
        name: "adhesionrate",
        component: () => import("@/domains/frames/views/AdhesionRateFrame.vue"),
      },
      {
        path: "favorite-card",
        name: "favoritecard",
        component: () => import("@/domains/frames/views/FavoriteCardFrame.vue"),
      },
      {
        path: "monitor-production",
        name: "monitorproduction",
        component: () =>
          import("@/domains/frames/views/MonitorProductionFrame.vue"),
      },
    ],
  },
  {
    path: "/dev",
    name: "dev",
    component: () => import("@/domains/dev/views/Dev.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      icon: "monitor",
      title: "Dev",
    },
  },
  {
    path: "/tech-kpis",
    name: "tech-kpis",
    component: () => import("@/domains/dev/views/TechKPIs.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      icon: "monitor",
      title: "KPI",
    },
  },
  {
    path: "/tech-performance",
    name: "tech-performance",
    component: () => import("@/domains/dev/views/TechPerformance.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      icon: "monitor",
      title: "Tech-performance",
    },
  },
  {
    path: "/list-absolute-ofs",
    name: "list-absolute-ofs",
    component: () => import("@/domains/dev/views/ListAbsoluteOFs.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      icon: "database",
      title: "List-absolute-ofs",
    },
  },
];

export default routes;
