import {unref} from "vue";
import {useSectorTree} from "./useSectorTree";
import {MaybeRef} from "@vueuse/core";
import {OplitSimulationPeriod, SectorLike} from "@/interfaces";
import {
  Calendar,
  getAllChildrenFrom,
  getImportantKeys,
  getOperatorsKeys,
  isWorkDay,
  levelCorresp,
  SectorToParse,
} from "@oplit/shared-module";
import {serverTimestamp} from "firebase/firestore";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {saveEventInFirestore} from "@/domains/planning/domains/events/utils/eventSaver";
import moment from "moment";
import _ from "lodash";
import {
  useCalendars,
  useOrderedCalendars,
  useSectorCalendars,
} from "./useCalendar";
import {i18n} from "@/i18n";
import loggerHelper from "@/tscript/loggerHelper";
import {storeToRefs} from "pinia";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useMainStore} from "@/stores/mainStore";
import {useSimulationStore} from "@/stores/simulationStore";
import {useParametersStore} from "@/domains/parameters/stores/parametersStore";

export const useSchedulingCapaSectorUpdater = (
  perimeter: MaybeRef<SectorLike>,
  period?: MaybeRef<OplitSimulationPeriod>,
) => {
  const {userData, disablePgRefresh, pgSse, apiClient} = storeToRefs(
    useMainStore(),
  );
  const {saveSSEAndListen} = useSimulationStore();
  const {selectedSimulation, areSchedulingSimulationUpdatesDisabled} =
    storeToRefs(useSchedulingStore());
  const {loadClientParametersList} = useParametersStore();

  const {sectorTree} = useSectorTree(perimeter);
  const calendars = useCalendars();
  const sectorCalendars = useSectorCalendars(perimeter, period);
  const orderedCalendars = useOrderedCalendars(perimeter, period);

  const {t} = i18n;

  const forceDateToWorkday = async (date: string) => {
    if (isWorkDay(date, unref(orderedCalendars))) return;
    const {client_id, name: user_name, id: user_id} = unref(userData) || {};
    const {
      id: secteur_id,
      name: secteur_name,
      level,
      type,
      collection,
    } = unref(sectorTree) || {};
    let {site_id = null} = unref(sectorTree);
    if (!site_id && collection === "sites") site_id = secteur_id;
    const existing = unref(sectorCalendars).find((x: any) => x.date === date);
    let calendar: Calendar = {},
      id: string;
    if (existing) {
      calendar = {isWorkday: true};
      id = existing.id;
    } else {
      id = dbHelper.getCollectionId("daily_calendar");
      calendar = {
        id,
        client_id,
        site_id,
        isWorkday: true,
        date,
        secteur_id,
        secteur_name,
        level,
        type,
        collection,
      };
      levelCorresp.forEach((level) => {
        if (unref(sectorTree)[level.type + "_id"])
          calendar[level.type + "_id"] = unref(sectorTree)[level.type + "_id"];
      });
    }
    calendar = {
      ...calendar,
      id,
      updated_at: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
      updated_by: {user_name, user_id},
      trigger_function: false,
      status: "active",
    };

    // save the document in firestore
    await dbHelper.setDataToCollection("daily_calendar", id, calendar, true);

    // update the calendars array in the store
    let newCalendars: Calendar[];
    if (existing) {
      newCalendars = calendars.value.map((x) =>
        x.id === id ? {...x, ...calendar} : x,
      );
    } else newCalendars = [...calendars.value, calendar];
    calendars.value = newCalendars;

    return calendar;
  };

  const newQuickEvent = async (
    child: any,
    title: string,
    periode: [string, string],
    shouldNotForceOpenCalendarDays: boolean,
  ) => {
    const {id: simulation_id} = unref(selectedSimulation) || {};
    if (unref(areSchedulingSimulationUpdatesDisabled)) return false;
    const {id: user_id, first_name, last_name, client_id} = unref(userData);
    const useSse = unref(pgSse).includes("capa");

    const sector = unref(sectorTree);
    const {secteur_id, secteur_name} = sector;
    if (!sector?.id) return false;

    //We get the msd parameters
    const [, parametres_msd = {}] = await loadClientParametersList();

    //Filter unnecessary fields in operateurs
    if (child.operateurs?.length) {
      child.operateurs = child.operateurs.map((x: any) =>
        getOperatorsKeys(x, parametres_msd),
      );
    } else if (child.machine_tags?.length) {
      child.machine_tags = child.machine_tags.map((x: any) =>
        getOperatorsKeys(x, parametres_msd),
      );
    }

    const initial = getImportantKeys(sector, parametres_msd);

    //We fill the child
    const unit = sector.unite || sector.unit || null;
    child = {
      ...child,
      secteur_id,
      secteur_name,
      periode: periode,
      initial,
      unit,
    };

    //We prepare the event
    const id = dbHelper.getCollectionId("events");
    const event = {
      id,
      client_id,
      title,
      impact: {},
      secteur_ids: [secteur_id],
      unit,
      type: "capa",
      status: "active",
      created_at: serverTimestamp(),
      created_by: {user_id, first_name, last_name},
      simulation_id,
      is_quick_event: true,
      is_scheduling: true,
      children: [child],
      updated_at: serverTimestamp(),
      updated_by: {user_id, first_name, last_name},
    };

    const error = await saveEventInFirestore(event, {
      use_event_sse: useSse,
      disable_pg_refresh: unref(disablePgRefresh),
      shouldErasePreviousMacroEvent: false,
      storeCommitCallback: () => (disablePgRefresh.value = false),
      sseCallback: (event: Event) =>
        saveSSEAndListen({
          data: event,
          dataType: "added",
          sectorTree: sector,
        }),
    });
    if (error) {
      loggerHelper.error(error);
      return;
    }
    if (!shouldNotForceOpenCalendarDays) {
      const calendarUpdates: Calendar[] = [];

      // We force the calendar to be workdays for the period
      let dateToChange = periode[0];
      while (dateToChange <= periode[1]) {
        const calendarItem = await forceDateToWorkday(dateToChange);
        if (calendarItem) calendarUpdates.push(calendarItem);
        dateToChange = moment(dateToChange).add(1, "days").format("YYYY-MM-DD");
      }

      // we prepare the affected sectors to be updated by the calendar
      const {
        id: secteur_id,
        level: secteur_level,
        collection: secteur_collection,
      } = sector;

      const affectedSectors: SectorToParse[] = [
        {secteur_id, secteur_collection, secteur_level},
        ...getAllChildrenFrom(sector).map(
          ({
            id,
            collection,
            level,
          }: {
            id: string;
            collection: string;
            level: number;
          }) => ({
            secteur_id: id,
            secteur_collection: collection,
            secteur_level: level,
          }),
        ),
      ];
      levelCorresp.forEach((level) => {
        if (!sector[level.type + "_id"]) return;
        affectedSectors.push({
          secteur_id: sector[level.type + "_id"],
          secteur_collection: level.collection,
          secteur_level: level.level,
        });
      });
      const uniqAffectedSectors = _.uniqBy(affectedSectors, "secteur_id");

      // We send the calendar updates and the affected sectors to the api for parsing
      if (calendarUpdates.length > 0) {
        await apiClient.value.postRequest(`/api/calendar/save-calendar`, {
          calendar_updates: calendarUpdates,
          affected_sectors: uniqAffectedSectors,
        });
      }
    }
    return event;
  };

  const updateSector = async (
    fieldModel: string,
    val: number | string,
    periode: [string, string],
    params?: {shouldNotForceOpenCalendarDays?: boolean},
  ) => {
    const child = {[fieldModel]: val};
    const {shouldNotForceOpenCalendarDays} = params || {};
    //We create the event
    return newQuickEvent(
      child,
      `${fieldModel} Modification ${t("Commons.à")} ${val}`,
      periode,
      !!shouldNotForceOpenCalendarDays,
    );
  };

  return {
    updateSector,
  };
};
