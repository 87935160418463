<script setup lang="ts">
import {computed, inject, nextTick, onMounted, ref} from "vue";
import {storeToRefs} from "pinia";
import moment from "moment";
import {useI18n} from "vue-i18n";
import FChip from "@/components/Global/Homemade/Commons/FChip.vue";
import HelpdeskLink from "@/components/Global/Homemade/Commons/HelpdeskLink.vue";
import FButton from "@/components/Global/Homemade/Buttons/FButton.vue";
import {useMainStore} from "@/stores/mainStore";
import {oplitClient} from "@/api";
import {DATE_WITH_TIME_FORMAT, LOCALES} from "@oplit/shared-module";
import {Segment} from "@/interfaces";

interface ProductNewsPopoverProps {
  displayLabel?: boolean;
}

defineProps<ProductNewsPopoverProps>();

const segment = inject<Segment>("segment");

const {t} = useI18n();

const {userParameters, userData} = storeToRefs(useMainStore());
const {setUserParameter} = useMainStore();

const isOpened = ref<boolean>(false);
const hasNewChip = ref<boolean>(false);

const locale = computed<(typeof LOCALES)[keyof typeof LOCALES]>(() =>
  Object.values(LOCALES).includes(userData.value.locale)
    ? userData.value.locale
    : LOCALES.EN,
);

async function setLastReadProductRelease(shouldTrack: boolean): Promise<void> {
  const last_product_release_read_date = moment().format(DATE_WITH_TIME_FORMAT);

  setUserParameter({last_product_release_read_date});

  if (shouldTrack) {
    segment.value.track("[General] Product Release Link Clicked", {
      locale: locale.value,
    });
  }

  await nextTick();

  hasNewChip.value = false;
}

onMounted(async () => {
  const {last_product_release_read_date} = userParameters.value;

  const [hasNewProductRelease] =
    await oplitClient.apiConnectorsCrispHasNewProductReleasePost({
      start_date: last_product_release_read_date,
      locale: locale.value,
    });

  if (!hasNewProductRelease) return;

  isOpened.value = true;
  hasNewChip.value = true;
});
</script>

<template>
  <v-menu v-model="isOpened" location="right" :offset="[32, 0]" persistent>
    <template #activator="{props}">
      <slot name="activator" v-bind="props">
        <v-list-item
          v-bind="props"
          :class="{'v-list-item--active': isOpened}"
          class="cursor-pointer"
          rounded="lg"
        >
          <v-list-item-title class="fd-flex-center gap-6">
            <OpalIcon
              name="speaker"
              view-box="0 0 24 24"
              size="24"
              stroke-width="0.5"
              style="flex: 0 0 24px"
              clickable
            />

            <template v-if="displayLabel">
              {{ t("ProductNewsPopover.activator_label") }}
            </template>
          </v-list-item-title>
        </v-list-item>
      </slot>
    </template>

    <div class="product-news-popover">
      <div class="fd-flex-center gap-4">
        <FChip
          v-if="hasNewChip"
          color="pink-500"
          rounded="lg"
          class="pa-2"
          large
        >
          {{ t("ProductNewsPopover.chip_text") }}
        </FChip>

        <span class="flex-1 fbody-1 text-grey-900 semi-bold">
          {{ t("ProductNewsPopover.popover_title") }}
        </span>

        <vue-feather
          type="x"
          stroke="rgb(var(--v-theme-grey-400))"
          class="cursor-pointer"
          @click="() => setLastReadProductRelease(false)"
        />
      </div>

      <div class="text-grey-700">
        {{ t("ProductNewsPopover.popover_body") }}
      </div>

      <HelpdeskLink
        class="align-self-end"
        :ids="{[LOCALES.FR]: 'iodmq', [LOCALES.EN]: 'vnk7g0'}"
        page-type="category"
        @click="() => setLastReadProductRelease(true)"
      >
        <FButton outlined>
          <span class="fbody-2 text-newMainText">
            {{ t("ProductNewsPopover.button_text") }}
          </span>

          <template #append-icon>
            <OpalIcon
              name="external-link"
              viewBox="0 0 24 24"
              stroke="rgb(var(--v-theme-newMainText))"
              class="ml-1"
            />
          </template>
        </FButton>
      </HelpdeskLink>
    </div>
  </v-menu>
</template>

<style scoped lang="scss">
.product-news-popover {
  width: 364px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}
</style>
