<script setup lang="ts">
import {computed, mergeProps, inject} from "vue";
import {storeToRefs} from "pinia";
import {useI18n} from "vue-i18n";
import FButton from "@/components/Global/Homemade/Buttons/FButton.vue";
import FavoriteWrapper from "@/components/Commons/FavoriteWrapper.vue";
import {useMainStore} from "@/stores/mainStore";
import {useNavigation} from "@/composables/useNavigation";
import {UserParametersFavorites} from "@/domains/parameters/types";
import {getSortedDropdownOptionsList} from "@/tscript/utils/generalHelpers";
import {levelCorresp} from "@oplit/shared-module";
import {Sector} from "@/interfaces";

interface FavoriteDropdownProps {
  // handles redirection to the simulation page upon a click on a favorite
  preventRedirect?: boolean;
}

const props = withDefaults(defineProps<FavoriteDropdownProps>(), {
  preventRedirect: false,
});

const {t} = useI18n();

const {
  userParameters,
  breadcrumbs,
  simulation,
  stationsAndMachinesTagsGroupedById,
  userData,
} = storeToRefs(useMainStore());

const {updateBreadcrumbs} = useNavigation();

const beforeChange = inject("beforeChange", () => true);

const iconProps = {
  icon: "star",
  iconSize: "14",
  iconFill: "rgb(var(--v-theme-newYellowRegular))",
  iconStroke: "rgb(var(--v-theme-newYellowRegular))",
};

const favoritesSectors = computed<Sector[]>(() => {
  const favoritesSectors = new Set([
    ...(userParameters.value[UserParametersFavorites.Sectors] || []),
    // FIXME: remove after migrating data from `users` to `parametres_user` (OPL-8249)
    ...(userData.value?.favorites?.map(({secteur_id}) => secteur_id) || []),
  ]);

  if (!favoritesSectors.size) return [];

  return getSortedDropdownOptionsList<Sector>(
    Array.from(
      favoritesSectors,
      (sectorId) => stationsAndMachinesTagsGroupedById.value[sectorId]?.[0],
    ).filter(Boolean),
    "name",
  );
});

function isCurrentSector(sector: Sector): boolean {
  return breadcrumbs.value.at(-1).id === sector.id;
}
async function onSectorClick(sector: Sector): Promise<void> {
  const success = await beforeChange();
  if (!success) return;
  updateBreadcrumbs(sector, levelCorresp[sector.level], {
    simulationId: simulation.value?.id,
    stayInPage: props.preventRedirect,
  });
}
</script>

<template>
  <v-menu :min-width="250" :offset="[8, 0]" close-on-content-click>
    <template #activator="{props}">
      <FButton
        v-bind="mergeProps(props, iconProps)"
        :outlined="['newLayerBackground']"
        class="gapped-button"
        medium
        data-testid="favorites-button"
      >
        {{ t("Commons.favorites.button_text") }}

        <template #append-icon>
          <vue-feather type="chevron-down" size="16" />
        </template>
      </FButton>
    </template>

    <div
      class="sectors-favorites-dropdown keep-scrollbar padded-scrollbar dense-scrollbar"
    >
      <template v-if="!favoritesSectors.length">
        <div class="sectors-favorites-dropdown__no-favorites">
          <strong>{{ t("Commons.favorites.empty_list_title") }}</strong>
          <div>
            {{ t("Commons.favorites.empty_list_text") }}
          </div>
        </div>
      </template>

      <FavoriteWrapper
        v-for="sector in favoritesSectors"
        :key="sector.id"
        :entity-id="sector.id"
        :parameters-key="UserParametersFavorites.Sectors"
        :class="{
          'sectors-favorites-dropdown__favorite-row-current-sector':
            isCurrentSector(sector),
        }"
        class="sectors-favorites-dropdown__favorite-row justify-space-between"
        @click="() => onSectorClick(sector)"
      >
        {{ sector.name }}
      </FavoriteWrapper>
    </div>
  </v-menu>
</template>

<style lang="scss" scoped>
.sectors-favorites-dropdown {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: overlay;
}

.sectors-favorites-dropdown__no-favorites {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  max-width: 250px;
}

.sectors-favorites-dropdown__favorite-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  cursor: pointer;
  color: rgb(var(--v-theme-newMainText));
  padding: 4px calc(var(--g-scrollbar-area) * 0.75) 4px 16px;
  line-height: 2;
  transition: all 0.25s;

  &:hover,
  &.sectors-favorites-dropdown__favorite-row-current-sector {
    background: rgb(var(--v-theme-newHover));
  }

  &.sectors-favorites-dropdown__favorite-row-current-sector {
    font-weight: bold;
  }
}
</style>
